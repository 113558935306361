import { useQuery } from "@tanstack/react-query";
import { format, parseISO } from "date-fns";
import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import { getMovementSheet } from "src/api";
import { PrinterIcon } from "src/assets/svg";
import { CustomDataTable } from "src/components";

const Sheets = () => {
	const [searchParams] = useSearchParams();

	const page = searchParams.get("page") ?? 1;
	const per_page = searchParams.get("per_page") ?? 50;

	const columns = [
		{
			name: "Date",
			selector: (row) => format(parseISO(row?.created_at), "PPP"),
			compact: true,
		},
		{
			name: "Driver Name",
			selector: (row) => row?.driver_name,
			compact: true,
		},
		{
			name: "Created By",
			selector: (row) => row?.rep_name,
			compact: true,
		},
		{
			name: "Location",
			selector: (row) => row?.locationName,
			compact: true,
		},
		{
			name: "Orders",
			selector: (row) => row?.order_ids,
			style: { textTransform: "capitalize" },
			compact: true,
		},
		{
			name: "",
			cell: (row) => (
				<div className="flex gap-4">
					<Link to={`/sheets/${row.id}`} target="_blank">
						<PrinterIcon className="cursor-pointer stroke-primary text-primary" title="Print Movement Sheet" />
					</Link>
				</div>
			),
			width: "80px",
			compact: true,
		},
	];

	const { data } = useQuery({
		queryKey: ["sheets", page, per_page],
		queryFn: () => getMovementSheet({ page, per_page }),
		suspense: true,
	});

	return (
		<>
			<div>
				<div className="flex items-center justify-between my-[2rem]">
					<h1 className="text-black text-[2rem] font-bold">Movement Sheets</h1>
				</div>
				<CustomDataTable columns={columns} data={data?.data?.data} paginationTotalRows={data?.data?.total} />
			</div>
		</>
	);
};

export default Sheets;
