import React from "react";
import { Menu, MenuItem, Sidebar } from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom";
import { useProtectedRoutesContext } from "src/context/ProtectedRoutes";
import {
	CustomersIcon,
	DashboardIcon,
	LogoutIcon,
	OrdersIcon,
	ReportIcon,
	SettingsIcon,
	TrackerIcon,
	UsersIcon,
	MetricsIcon,
	// HardwareIcon,
} from "src/assets/svg";
import Logo from "src/assets/logo.png";

const SideBar = () => {
	const { setUser, setToken, user } = useProtectedRoutesContext();

	const { pathname } = useLocation();
	return (
		<Sidebar backgroundColor="white" customBreakPoint="1024px" width="200px">
			<div>
				<img src={Logo} alt="CleanAce" />
			</div>
			<Menu
				menuItemStyles={{
					root: ({ active }) => {
						return {
							color: active ? "var(--primary-color)" : "#5E6C85",
							backgroundColor: active ? "var(--light-primary-color)" : undefined,
							marginBottom: 2,
							borderRadius: 10,
						};
					},
					button: {
						paddingInline: 5,
					},
					icon: {
						marginRight: 5,
					},
					label: {
						fontSize: "1.4rem",
						fontWeight: "500",
					},
				}}
				rootStyles={{
					width: "80%",
					marginInline: "auto",
					marginTop: 20,
				}}
			>
				<MenuItem component={<Link to="/" />} icon={<DashboardIcon />} active={pathname === "/dashboard"}>
					Dashboard
				</MenuItem>
				<MenuItem component={<Link to="/orders" />} icon={<OrdersIcon />} active={pathname.endsWith("orders")}>
					Orders
				</MenuItem>
				<MenuItem component={<Link to="/sheets" />} icon={<OrdersIcon />} active={pathname.endsWith("sheets")}>
					Movement Sheets
				</MenuItem>
				<MenuItem component={<Link to="/customers" />} icon={<CustomersIcon />} active={pathname.endsWith("customers")}>
					Customers
				</MenuItem>
				<MenuItem component={<Link to="/track" />} icon={<TrackerIcon />} active={pathname.endsWith("track")}>
					Track Item
				</MenuItem>
				{user?.role === "admin" && (
					<>
						<MenuItem component={<Link to="/reports" />} icon={<ReportIcon />} active={pathname.endsWith("reports")}>
							Reports
						</MenuItem>
						<MenuItem component={<Link to="/users" />} icon={<UsersIcon />} active={pathname.endsWith("users")}>
							Users
						</MenuItem>
						<MenuItem component={<Link to="/metrics" />} icon={<MetricsIcon />} active={pathname.endsWith("metrics")}>
							Metrics
						</MenuItem>
						<MenuItem component={<Link to="/settings" />} icon={<SettingsIcon />} active={pathname.endsWith("settings")}>
							Settings
						</MenuItem>
					</>
				)}

				<MenuItem
					icon={<LogoutIcon />}
					onClick={() => {
						setToken(null);
						setUser(null);
						localStorage.clear();
						window.location.reload();
					}}
				>
					Logout
				</MenuItem>
			</Menu>
		</Sidebar>
	);
};

export default SideBar;
