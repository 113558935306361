import { useMutation, useQuery } from "@tanstack/react-query";
import { Formik } from "formik";
import React from "react";
import { generateMovementSheet, getAllStaff } from "src/api";
import { renderSuccessMessage } from "src/assets/functions";
import { CustomSelectInput } from "src/components";
import { useProtectedRoutesContext } from "src/context/ProtectedRoutes";
import * as Yup from "yup";

const MovementSheet = ({ bags, setIsOpen, refetch, setSelectedRows }) => {
	const { user } = useProtectedRoutesContext();

	const initialValues = {
		locationId: user?.location_id,
		storeId: user?.id,
		driverId: "",
		order_ids: bags,
		total_bags: bags?.length,
	};
	const validationSchema = Yup.object().shape({
		locationId: Yup.string().required("Location is required"),
		storeId: Yup.string().required("Store is required"),
		driverId: Yup.string().required("Driver is required"),
		order_ids: Yup.array().min(1, "Please select at least 1 order"),
	});

	const { data } = useQuery({
		queryKey: ["allStaff"],
		queryFn: () => getAllStaff({ departmentId: 25 }),
		suspense: true,
	});
	const { mutate, isLoading } = useMutation(generateMovementSheet, {
		onSuccess: ({ message }) =>
			renderSuccessMessage(message).then(() => {
				setIsOpen(false);
				refetch();
				setSelectedRows([]);
			}),
	});

	const staff = data?.data?.map((staff) => ({ value: staff?.id, label: staff?.fullName }));

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values) => {
				const temp = { ...values };
				temp.order_ids = temp.order_ids?.map((order) => order?.serial_number)?.join(",");
				mutate(temp);
			}}
		>
			{({ values, handleChange, handleSubmit }) => (
				<form onSubmit={handleSubmit} className="flex flex-col gap-5">
					<div className="flex flex-col gap-1">
						<label className="text-[1.4rem] text-lightText font-medium mb-[10px]">Orders</label>
						{bags?.map((order) => order?.serial_number)?.join(", ")}
					</div>
					<CustomSelectInput
						label="Driver"
						name="driverId"
						placeholder="Select Driver"
						value={values.driverId}
						options={staff}
						onChange={handleChange}
					/>
					<button type="submit" className="btn-primary" disabled={isLoading}>
						Generate
					</button>
				</form>
			)}
		</Formik>
	);
};

export default MovementSheet;
