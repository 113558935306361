import { useQuery } from "@tanstack/react-query";
import { format, parse } from "date-fns";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { getItemSettings, getOrderById } from "src/api";
import { groupByOptionId, renderCurrency } from "src/assets/functions";
import QRCode from "qrcode";
import Logo from "src/assets/logo2.png";

const Receipt = () => {
	const { orderId } = useParams();

	const componentRef = React.useRef(null);

	const reactToPrintContent = React.useCallback(() => {
		return componentRef.current;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [componentRef.current]);

	const handlePrint = useReactToPrint({
		content: reactToPrintContent,
		documentTitle: "Print Tag",
		removeAfterPrint: true,
	});

	const { data } = useQuery({
		queryKey: ["order", orderId],
		queryFn: () => getOrderById(orderId),
		suspense: true,
	});

	const order = data?.data?.at(0);

	return (
		<div className="flex flex-col items-center justify-center min-h-[50vh] gap-3">
			<ComponentToPrint ref={componentRef} order={order} />
			<button className=" btn-primary" onClick={handlePrint}>
				Print
			</button>
		</div>
	);
};

const ComponentToPrint = React.forwardRef((props, ref) => {
	const { order } = props;
	const [qr, setQr] = useState("");

	const transactionGroups = groupByOptionId(order?.transactions);
	const orderID = order?.id;

	QRCode.toDataURL(orderID.toString(), { type: "png" }).then((img) => {
		setQr(img);
	});

	const { data } = useQuery({
		queryKey: ["item-Settings"],
		queryFn: () => getItemSettings(),
		suspense: true,
	});

	const notes = data.data?.at(0)?.notes;

	return (
		<div ref={ref} className="">
			<div className="flex flex-col items-center w-[300px] bg-white px-3 py-4">
				<div className="bg-gray-600">
					<img src={Logo} alt="CleanAce" className="h-[70px]" />
				</div>

				<div className="flex items-center justify-between mb-8 w-[90%]">
					<div>
						<p className="font-extrabold text-[2.4rem]">{order?.serial_number}</p>
						<p className="font-bold text-[2rem]">{order?.transactions?.length} Pieces</p>
					</div>
					<img src={qr} alt="qrcode" className="w-[100px] aspect-square" />
				</div>

				<p className="font-semibold text-[1.6rem] uppercase">{order?.customer?.full_name}</p>
				<p className="text-[1.4rem]">{order?.customer?.email}</p>
				<p className="text-[1.4rem]">{order?.customer?.phone}</p>
				<p className="my-5">--------------------</p>

				{Object.entries(transactionGroups)?.map(([key, items], index) => {
					const { option_name, price, expressPrice } = JSON.parse(key);

					return (
						<div className="w-full" key={index}>
							<div className="flex items-start gap-3">
								<div className="flex-1">
									<p className="font-semibold text-left capitalize">
										{option_name} <span className="lowercase">x {items?.filter((item) => item?.number === "0")?.length}</span>
									</p>
								</div>
								<p>{renderCurrency(order?.isExpress ? expressPrice : price)}</p>
								<p>
									{renderCurrency(
										parseInt(order?.isExpress ? expressPrice : price) * items?.filter((item) => item?.number === "0")?.length
									)}
								</p>
							</div>
							{items?.map((item, key) => {
								const extra_info = JSON.parse(item?.item?.extra_info);
								return (
									<p className="pl-8 text-[1.4rem] text-left" key={key}>
										{extra_info?.color} | {item?.item?.brand} | {extra_info?.pattern} | {extra_info?.material}
										{!!extra_info?.style && ` | ${extra_info?.style}`}
										{!!extra_info?.stains && ` | ${extra_info?.stains}`}
										{!!extra_info?.damage && ` | ${extra_info?.damage}`}
									</p>
								);
							})}
						</div>
					);
				})}

				<div className="my-10 w-[80%]">
					<p className="text-[1.4rem] font-semibold flex justify-between items-center">
						DISCOUNT: <span>{renderCurrency(order?.discount)}</span>
					</p>
					<p className="text-[1.4rem] font-semibold flex justify-between items-center">
						TOTAL: <span>{renderCurrency(order?.bill)}</span>
					</p>
					<p className="text-[1.4rem] font-semibold flex justify-between items-center">
						BALANCE DUE: <span>{renderCurrency(order?.bill - order?.paidAmount)}</span>
					</p>
				</div>

				<p className="font-extrabold text-[2.4rem]">
					Due: {format(parse(order?.dateTimeOut, "yyyy-MM-dd HH:mm:ss", new Date()), "MMM dd")}{" "}
					<span className="font-normal text-[1.2rem]">
						{format(parse(order?.dateTimeOut, "yyyy-MM-dd HH:mm:ss", new Date()), "hh:mm a")}
					</span>
				</p>
				<p className="text-[1.4rem]">Placed On: {format(parse(order?.dateTimeIn, "yyyy-MM-dd HH:mm:ss", new Date()), "PPP")}</p>
				<p className="text-[1.4rem] mb-6">Placed By: {order?.user?.fullName}</p>

				<textarea
					rows="15"
					className={`text-[1.2rem] placeholder:text-[1.2rem] font-semibold text-black w-full text-center`}
					name="notes"
					value={notes}
					disabled
				></textarea>
			</div>
		</div>
	);
});

export default Receipt;
