import React from "react";
import CustomDataTable from "../CustomDataTable";
import { format, parse, parseISO } from "date-fns";
import { useQuery } from "@tanstack/react-query";
import { getUserClockInOuts } from "src/api";

const ClockInOut = ({ selectedStaff }) => {
	const columns = [
		{
			name: "Date",
			selector: (row) => format(parseISO(row?.created_at), "PPP"),
			compact: true,
			width: "150px",
		},
		{
			name: "Clock In",
			selector: (row) => format(parse(row?.clockin_time, "HH:mm:ss", new Date()), "PPP"),
			compact: true,
		},
		{
			name: "Clock Out",
			selector: (row) => format(parse(row?.clockout_time, "HH:mm:ss", new Date()), "PPP"),
			compact: true,
		},
	];

	const { data } = useQuery({
		queryKey: ["clocks", selectedStaff?.id],
		queryFn: () => getUserClockInOuts(selectedStaff?.id),
		suspense: true,
	});

	return (
		<div>
			<CustomDataTable columns={columns} data={data?.data} paginationServer={false} />
		</div>
	);
};

export default ClockInOut;
