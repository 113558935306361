import { useMutation, useQuery } from "@tanstack/react-query";
import { Formik } from "formik";
import React from "react";
import { CustomSelectInput, CustomTextInput } from "src/components";
import * as Yup from "yup";
import { addLocation, editLocation, getAllRoutes } from "src/api";
import { renderSuccessMessage } from "src/assets/functions";

const AddLocation = ({ setOpenModal, refetch, selectedLocation }) => {
	const initialValues = selectedLocation
		? {
				id: selectedLocation?.id,
				location_name: selectedLocation?.locationName,
				store_code: selectedLocation?.store_code,
				address: selectedLocation?.address,
				phone_number: selectedLocation?.phoneNumber,
				route_id: selectedLocation?.route_id,
		  }
		: {
				location_name: "",
				store_code: "",
				address: "",
				phone_number: "",
				route_id: "",
		  };

	const validationSchema = Yup.object().shape({
		location_name: Yup.string().required("Location name is required"),
		store_code: Yup.string().required("Location short code is required"),
		address: Yup.string().required("Location address is required"),
		phone_number: Yup.string().required("Location phone number is required"),
		route_id: Yup.string().required("Location route is required"),
	});

	const { mutate, isLoading } = useMutation(selectedLocation ? editLocation : addLocation, {
		onSuccess: ({ message }) => {
			renderSuccessMessage(message).then(() => {
				refetch();
				setOpenModal(false);
			});
		},
	});

	const { data } = useQuery({
		queryKey: ["getAllRoutes"],
		queryFn: () => getAllRoutes(),
		suspense: true,
	});

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values) => {
				mutate(values);
			}}
		>
			{({ values, handleChange, handleSubmit }) => (
				<form onSubmit={handleSubmit} className="flex flex-col">
					<div className="flex flex-col gap-10 my-10 mb-20 ">
						<CustomTextInput
							label="Location Name"
							name="location_name"
							onChange={handleChange}
							value={values.location_name}
							placeholder="Enter Name..."
						/>
						<CustomTextInput
							label="Short Code"
							name="store_code"
							onChange={handleChange}
							value={values.store_code}
							placeholder="Enter code..."
						/>
						<CustomTextInput
							label="Address"
							name="address"
							onChange={handleChange}
							value={values.address}
							placeholder="Enter Address..."
						/>
						<CustomTextInput
							label="Phone"
							name="phone_number"
							onChange={handleChange}
							value={values.phone_number}
							placeholder="Enter Phone..."
						/>
						<CustomSelectInput
							label="Route"
							name="route_id"
							onChange={handleChange}
							value={values.route_id}
							placeholder="Enter Route"
							options={data?.data?.map((option) => ({
								value: option?.id,
								label: option?.route_name,
							}))}
						/>
					</div>
					<div className="flex gap-3 ml-auto">
						<button className="btn" type="button" onClick={() => setOpenModal(false)}>
							Cancel
						</button>
						<button className="btn-primary" disabled={isLoading}>
							{selectedLocation ? "Edit" : "Add"}
						</button>
					</div>
				</form>
			)}
		</Formik>
	);
};

export default AddLocation;
