import { useQuery } from "@tanstack/react-query";
import { getDueOrders, getMetricsOverview } from "../../../api";
import { renderCurrency, renderNumber } from "../../../assets/functions";
import valueIcon from "src/assets/images/value.png";
import { CustomDataTable } from "src/components";
import { useState } from "react";
import { format, parse } from "date-fns";
import { useProtectedRoutesContext } from "src/context/ProtectedRoutes";

const Overview = () => {
	const { user } = useProtectedRoutesContext();
	const [, setFirst] = useState({ duePage: 0, duePerPage: 10 });

	const store_id = user?.role === "admin" ? null : user?.location_id;

	const { data } = useQuery({
		queryKey: ["getMetricsOverview", store_id],
		queryFn: () => getMetricsOverview({ store_id }),
		suspense: true,
	});
	const { data: dueorders } = useQuery({
		queryKey: ["getDueOrders"],
		queryFn: getDueOrders,
		suspense: true,
	});

	const columns = [
		{
			name: "ID",
			selector: (row) => row?.id,
			width: "70px",
		},
		{
			name: "Customer",
			selector: (row) => row?.full_name,
		},
		{
			name: "Ready By",
			selector: (row) => row?.dateTimeOut && format(parse(row?.dateTimeOut?.split(" ")[0], "yyyy-MM-dd", new Date()), "PPP"),
		},
		{
			name: "Amount",
			selector: (row) => renderCurrency(row?.paidAmount),
		},
	];

	const discount = [
		{
			name: "ID",
			selector: (row) => row?.id,
			width: "70px",
		},
		{
			name: "Customer",
			selector: (row) => row?.full_name,
		},
		{
			name: "Discount",
			selector: (row) => renderCurrency(row?.discount),
		},
	];

	const staffperformance = [
		{
			name: "Name",
			selector: (row) => row?.fullName,
			width: "300px",
		},
		{
			name: "New Orders",
			selector: (row) => row?.ready,
		},
		{
			name: "Sales",
			selector: (row) => renderCurrency(row?.sales),
		},
		{
			name: "Revenue",
			selector: (row) => renderCurrency(row?.revenue),
		},
		{
			name: "Clean",
			selector: (row) => row?.clean,
		},
	];

	return (
		<>
			<div className="px-[2.5rem] mt-[50px]">
				<div className="grid grid-cols-2 mb-8 border border-gray-300 divide-x divide-gray-300 rounded-lg">
					<div className="px-4 py-5">
						<div className="flex justify-between">
							<p className="text-[1.4rem] text-darkText font-medium">Total Amount</p>
							<img src={valueIcon} alt="" />
						</div>
						<p className="text-[2rem] leading-[3rem] font-bold">{renderCurrency(data?.data?.at(0)?.at(0)?.value)}</p>
					</div>

					<div className="px-4 py-5">
						<div className="flex justify-between">
							<p className="text-[1.4rem] text-darkText font-medium">Revenue</p>
							<img src={valueIcon} alt="" />
						</div>
						<p className="text-[2rem] leading-[3rem] font-bold">{renderCurrency(data?.data?.at(0)?.at(0)?.revenue)}</p>
					</div>
				</div>
				<div className="grid grid-cols-2 mb-8 border border-gray-300 divide-x divide-gray-300 rounded-lg">
					<div className="px-4 py-5">
						<div className="flex justify-between">
							<p className="text-[1.4rem] text-darkText font-medium">VAT</p>
							<img src={valueIcon} alt="" />
						</div>
						<p className="text-[2rem] leading-[3rem] font-bold">{renderCurrency(data?.data?.at(0)?.at(0)?.VAT)}</p>
					</div>
					<div className="px-4 py-5">
						<div className="flex justify-between">
							<p className="text-[1.4rem] text-darkText font-medium">Discount Used</p>
							<img src={valueIcon} alt="" />
						</div>
						<p className="text-[2rem] leading-[3rem] font-bold">{renderCurrency(data?.data?.at(0)?.at(0)?.discount)}</p>
					</div>
				</div>
				<div className="grid grid-cols-3 mb-8 border border-gray-300 divide-x divide-gray-300 rounded-lg">
					<div className="px-4 py-5">
						<div className="flex justify-between">
							<p className="text-[1.4rem] text-darkText font-medium">Orders</p>
							<img src={valueIcon} alt="" />
						</div>
						<p className="text-[2rem] leading-[3rem] font-bold">{renderNumber(data?.data?.at(0)?.at(0)?.order_count)}</p>
					</div>
					<div className="px-4 py-5">
						<div className="flex justify-between">
							<p className="text-[1.4rem] text-darkText font-medium">Orders Cleaned</p>
							<img src={valueIcon} alt="" />
						</div>
						<p className="text-[2rem] leading-[3rem] font-bold">{renderNumber(data?.data?.at(0)?.at(0)?.orders_cleaned)}</p>
					</div>

					<div className="px-4 py-5">
						<div className="flex justify-between">
							<p className="text-[1.4rem] text-darkText font-medium">Pieces</p>
							<img src={valueIcon} alt="" />
						</div>
						<p className="text-[2rem] leading-[3rem] font-bold">{renderNumber(data?.data?.at(1)?.at(0)?.Pieces)}</p>
					</div>
				</div>
				<div>
					<h2 className="mb-[20px] text-primary mt-[50px]">Staff Performance</h2>
					<CustomDataTable columns={staffperformance} data={dueorders?.data?.$staffs} />
				</div>
				<div className="grid grid-cols-2 gap-[15px] mt-[100px]">
					<div>
						<h2 className="mb-[20px] text-primary">Overdue Orders</h2>
						<CustomDataTable
							columns={columns}
							data={dueorders?.data?.orders?.data}
							onChangePage={(page) => {
								setFirst((prevState) => ({ ...prevState, duePage: page }));
							}}
							onChangeRowsPerPage={(currentRowsPerPage) => {
								setFirst((prevState) => ({ ...prevState, duePerPage: currentRowsPerPage }));
							}}
						/>
					</div>
					<div>
						<h2 className="mb-[20px] text-primary">To be Cleaned - Ready by Today</h2>
						<CustomDataTable
							columns={columns}
							data={dueorders?.data?.dueToday?.data}
							onChangePage={(page) => {
								setFirst((prevState) => ({ ...prevState, duePage: page }));
							}}
							onChangeRowsPerPage={(currentRowsPerPage) => {
								setFirst((prevState) => ({ ...prevState, duePerPage: currentRowsPerPage }));
							}}
						/>
					</div>
					<div>
						<h2 className="mb-[20px] text-primary">To be Cleaned - Ready by Tomorrow</h2>
						<CustomDataTable
							columns={columns}
							data={dueorders?.data?.dueTomorrow?.data}
							onChangePage={(page) => {
								setFirst((prevState) => ({ ...prevState, duePage: page }));
							}}
							onChangeRowsPerPage={(currentRowsPerPage) => {
								setFirst((prevState) => ({ ...prevState, duePerPage: currentRowsPerPage }));
							}}
						/>
					</div>

					<div>
						<h2 className="mb-[20px] text-[#0000A7]">Discount Used</h2>
						<CustomDataTable
							columns={discount}
							data={dueorders?.data?.discount?.data}
							onChangePage={(page) => {
								setFirst((prevState) => ({ ...prevState, duePage: page }));
							}}
							onChangeRowsPerPage={(currentRowsPerPage) => {
								setFirst((prevState) => ({ ...prevState, duePerPage: currentRowsPerPage }));
							}}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default Overview;
