import { CustomDataTable, Modal } from "src/components";
import { EditIcon, DeleteIcon } from "src/assets/svg";
import { deleteRoute, getAllRoutes } from "src/api";
import { useMutation, useQuery } from "@tanstack/react-query";
import AddRoute from "src/modals/settings/AddRoute";
import { useState } from "react";
import { renderConfirmDialogue, renderErrorMessage, renderSuccessMessage } from "src/assets/functions";

const Routes = ({ openModal, setOpenModal, closeModal }) => {
	const [selectedroutedetails, setselectedroutedetails] = useState(null);

	const [isEditModalOpen, setIsEditModalOpen] = useState(false);

	const { data, refetch } = useQuery({
		queryKey: ["getAllRoutes"],
		queryFn: () => getAllRoutes(),
		suspense: true,
	});

	const { mutate } = useMutation(deleteRoute, {
		onSuccess: (data) => {
			renderSuccessMessage(data.message).then(() => {
				refetch();
			});
		},
		onError: (error) => {
			renderErrorMessage(error.message);
		},
	});

	const columns = [
		{
			name: "Route Name",
			selector: (row) => row?.route_name,
			compact: true,
		},
		{
			name: "Staff Name",
			selector: (row) => row?.fullName,
			compact: true,
		},
		{
			name: "",
			cell: (row) => (
				<div className="flex gap-4">
					<EditIcon
						className="cursor-pointer stroke-primary text-primary"
						onClick={() => {
							setselectedroutedetails(row);
							setIsEditModalOpen(true);
						}}
					/>
					<DeleteIcon
						className="cursor-pointer stroke-primary text-primary"
						onClick={() => {
							renderConfirmDialogue().then((result) => {
								if (result.isConfirmed) mutate(row?.uuid);
							});
						}}
					/>
				</div>
			),
			width: "50px",
			compact: true,
		},
	];

	return (
		<>
			<CustomDataTable columns={columns} data={data.data} />
			<Modal isOpen={openModal} setIsOpen={setOpenModal} title="Add Route">
				<AddRoute setOpenModal={setOpenModal} refetch={refetch} closeModal={closeModal} />
			</Modal>
			<Modal isOpen={isEditModalOpen} setIsOpen={setIsEditModalOpen} title="Edit Route">
				<AddRoute selectedroutedetails={selectedroutedetails} refetch={refetch} setOpenModal={setIsEditModalOpen} />
			</Modal>
		</>
	);
};

export default Routes;
