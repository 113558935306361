import React from "react";
import "./quantityinput.css";
import { AddIcon, MinusIcon } from "src/assets/svg";

export function QuantityInput({ value, onChange, editable }) {
	if (editable)
		return (
			<div className="quantity-input">
				<button
					className="quantity-input__modifier quantity-input__modifier--left"
					onClick={() => onChange(value > 0 ? value - 1 : 0)}
					type="button"
				>
					<MinusIcon />
				</button>
				<input
					className="quantity-input__screen"
					type="number"
					value={value}
					onChange={(event) => onChange(event.target.valueAsNumber)}
					min={0}
				/>
				<button className="quantity-input__modifier quantity-input__modifier--right" onClick={() => onChange(value + 1)} type="button">
					<AddIcon />
				</button>
			</div>
		);

	return (
		<div className="quantity-input">
			<button
				className="quantity-input__modifier quantity-input__modifier--left"
				onClick={() => onChange(value > 0 ? value - 1 : 0)}
				type="button"
			>
				<MinusIcon />
			</button>
			<p className="quantity-input__screen" type="text">
				{value}
			</p>
			<button className="quantity-input__modifier quantity-input__modifier--right" onClick={() => onChange(value + 1)} type="button">
				<AddIcon />
			</button>
		</div>
	);
}
