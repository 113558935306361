import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { getDiscountType } from "src/api";
import { EditIcon } from "src/assets/svg";
import { CustomDataTable, Modal } from "src/components";
import AddDiscount from "src/modals/settings/AddDiscount";

const Discounts = ({ openModal, setOpenModal }) => {
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	const [selectedpatterndetails, setselectedpatterndetails] = useState(null);

	const { data, refetch } = useQuery({
		queryKey: ["discount-type"],
		queryFn: getDiscountType,
		suspense: true,
	});

	const columns = [
		{
			name: "Discount Name",
			selector: (row) => row?.type,
			compact: true,
		},
		{
			name: "Discount Multiplier",
			selector: (row) => row?.percentage,
			compact: true,
		},
		{
			name: "",
			cell: (row) => (
				<div className="flex gap-4">
					<EditIcon
						className="cursor-pointer stroke-primary text-primary"
						onClick={() => {
							setselectedpatterndetails(row);
							setIsEditModalOpen(true);
						}}
					/>
				</div>
			),
			width: "50px",
			compact: true,
		},
	];

	return (
		<>
			<CustomDataTable columns={columns} data={data.data} />
			<Modal isOpen={openModal} setIsOpen={setOpenModal} title="Add Pattern Type">
				<AddDiscount setOpenModal={setOpenModal} refetch={refetch} />
			</Modal>
			<Modal isOpen={isEditModalOpen} setIsOpen={setIsEditModalOpen} title="Edit Pattern Type">
				<AddDiscount setOpenModal={setIsEditModalOpen} refetch={refetch} selectedDiscount={selectedpatterndetails} />
			</Modal>
		</>
	);
};

export default Discounts;
