import LineGraph from "../LineGraph";
import { useSearchParams } from "react-router-dom";
import { getMetricsPeriod } from "src/api";
import { useQuery } from "@tanstack/react-query";
import { options, createDataset } from "src/assets/constants";
import { ctrlPercentageMetric } from "src/assets/functions";

const PopularDaysGraph = ({ metricPeriod }) => {
	const [searchParams] = useSearchParams();

	const startDate = searchParams.get("startDate") || "2023-08-07";
	const endDate = searchParams.get("endDate") || "2023-08-14";

	const period = metricPeriod;

	const { data } = useQuery({
		queryKey: ["metricsPeriod", startDate, endDate, period],
		queryFn: () => getMetricsPeriod({ startDate, endDate, period }),
		suspense: true,
	});

	const popularMetricsData = data?.data?.popular;

	const prepareChartData = (data) =>
		data.map((entry) => ({
			x: entry.day_of_week,
			y: entry.order_count,
		}));

	const popularPeriod1Data = prepareChartData(
		popularMetricsData["popular-period-1"].popular
	);
	const popularPeriod2Data = prepareChartData(
		popularMetricsData["popular-period-2"].popular
	);

	const dataset1 = createDataset(popularPeriod1Data, "Order Count", "#999");
	const dataset2 = createDataset(popularPeriod2Data, "Order Count", "#0000A7");

	const chartData = {
		datasets: [dataset1, dataset2],
	};

	const [metricPercentage, percentageColor, percentageBg] = ctrlPercentageMetric(data?.data?.popular?.percentageDifference)

	const dayData1 = data?.data?.popular["popular-period-2"];
	const day1 = dayData1?.popular[dayData1.popular.length - 1];

	const dayOfWeek = day1?.day_of_week || "";
	const orderCount = day1?.order_count || 0;

	const dayData = data?.data?.popular["popular-period-1"];
	const day = dayData?.popular[dayData?.popular.length - 1];

	const dayOfWeek2 = day?.day_of_week || "";
	const orderCount2 = day?.order_count || 0;

	return (
		<div className="graph-box bg-white py-10 px-14 rounded-xl border">
			<div className="title flex gap-5 items-center mb-12">
				<h2 className="text-3xl font-semibold">Popular Days</h2>
				<span
					style={{
						color: percentageColor,
						backgroundColor: percentageBg,
					}}
					className={`text-xl transition-all text-[${percentageColor}] bg-[${percentageBg}] py-1 px-3 rounded-3xl !important`}
				>{`${metricPercentage}%`}</span>
			</div>
			<div className="days flex justify-between">
				<div className="p1 text-[#0000A7]">{`${dayOfWeek} ${orderCount}`}</div>
				<div className="p2">{`${dayOfWeek2} ${orderCount2}`}</div>
			</div>
			<div className="graph w-[430]">
				<LineGraph data={chartData} options={options} />
			</div>
		</div>
	);
};

export default PopularDaysGraph;
