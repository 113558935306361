import { CustomDataTable, Modal } from "src/components";
import { DeleteIcon, EditIcon } from "src/assets/svg";
import AddDepartment from "src/modals/settings/AddDepartment";
import { renderConfirmDialogue, renderErrorMessage, renderSuccessMessage } from "src/assets/functions";
import { useMutation, useQuery } from "@tanstack/react-query";
import { deleteDepartment, getAllDepartments } from "src/api";
import { useState } from "react";

const Department = ({ openModal, setOpenModal, closeModal }) => {
	const [editdepartment, seteditdepartment] = useState(null);
	const [editdepartmentModal, seteditdepartmentModal] = useState(false);

	const { mutate } = useMutation(deleteDepartment, {
		onSuccess: (data) => {
			renderSuccessMessage(data.message).then(() => {
				refetch();
			});
		},
		onError: (error) => {
			renderErrorMessage(error.message);
		},
	});

	const { data, refetch } = useQuery({
		queryKey: ["getAllDepartments"],
		queryFn: () => getAllDepartments(),
		suspense: true,
	});

	const columns = [
		{
			name: "Department",
			selector: (row) => row?.name,
			style: { textTransform: "capitalize" },
			grow: 1.5,
			compact: true,
		},
		{
			name: "",
			cell: (row) => (
				<div className="flex gap-4">
					<EditIcon
						className="cursor-pointer stroke-primary text-primary"
						onClick={() => {
							seteditdepartment(row);
							seteditdepartmentModal(true);
						}}
					/>
					<DeleteIcon
						className="cursor-pointer stroke-primary text-primary"
						onClick={() => {
							renderConfirmDialogue().then((result) => {
								if (result.isConfirmed) mutate(row?.id);
							});
						}}
					/>
				</div>
			),
			width: "50px",
			compact: true,
		},
	];

	return (
		<>
			<CustomDataTable columns={columns} data={data.data} />

			<Modal isOpen={openModal} setIsOpen={setOpenModal} title="Add Department">
				<AddDepartment
					refetch={refetch}
					setOpenModal={setOpenModal}
					closeModal={closeModal}
					getAllDefaultOptions={data?.data}
				/>
			</Modal>

			<Modal isOpen={editdepartmentModal} setIsOpen={seteditdepartmentModal} title="Edit Department">
				<AddDepartment
					editdepartment={editdepartment}
					setOpenModal={seteditdepartmentModal}
					refetch={refetch}
					getAllDefaultOptions={data?.data}
				/>
			</Modal>
		</>
	);
};

export default Department;
