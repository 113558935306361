import React, { useState } from "react";
import { useOrderContext } from "src/pages/Dashboard/Orders/context";
import { DeleteIcon } from "src/assets/svg";
import { removeItemAtIndex, renderCurrency } from "src/assets/functions";
import Modal from "../Modal";
import EditPrice from "src/modals/orders/EditPrice";

const OrderItem = ({ orderItem, index }) => {
	const { setOrderItems, isExpressDelivery } = useOrderContext();
	const [isOpen, setIsOpen] = useState(false);

	const extra_info = JSON.parse(orderItem.extra_info);

	return (
		<>
			<div className="flex items-center gap-4 mb-4">
				<DeleteIcon
					className="!stroke-primary cursor-pointer"
					onClick={() => setOrderItems((prevState) => removeItemAtIndex(prevState, index))}
				/>
				<div className="flex-1">
					<div className="flex items-center justify-between text-[1.4rem] text-darkText font-medium">
						<p className="capitalize">{orderItem.name}</p>
						<button className="border-0" onClick={() => setIsOpen(true)}>
							{isExpressDelivery ? renderCurrency(orderItem.expressPrice) : renderCurrency(orderItem.price)}
						</button>
					</div>
					<p className="text-lightText text-[1.2rem]">
						{orderItem.brand}
						{!!extra_info.color.length ? Object.values(extra_info).map((info) => info && `, ${info.toLowerCase()} `) : orderItem.tag_id}
					</p>
				</div>
			</div>
			<Modal isOpen={isOpen} setIsOpen={setIsOpen} title="Edit Price" size="xsm">
				<EditPrice setIsOpen={setIsOpen} index={index} />
			</Modal>
		</>
	);
};

export default OrderItem;
