import { CustomDataTable, Modal } from "src/components";
import { DeleteIcon, EditIcon } from "src/assets/svg";
import AddDelivery from "src/modals/settings/AddDelivery";
import { useState } from "react";
import { renderConfirmDialogue, renderCurrency, renderErrorMessage, renderSuccessMessage } from "src/assets/functions";
import { useMutation, useQuery } from "@tanstack/react-query";
import { deleteDeliveryOption, getDeliveryOptions } from "src/api";

const DeliveryOption = ({ openModal, setOpenModal }) => {
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	const [selectedOption, setSelectedOption] = useState(null);

	const { data, refetch } = useQuery({
		queryKey: ["deleivery-options"],
		queryFn: getDeliveryOptions,
		suspense: true,
	});

	const { mutate } = useMutation(deleteDeliveryOption, {
		onSuccess: (data) => {
			renderSuccessMessage(data.message).then(() => refetch());
		},
		onError: (error) => renderErrorMessage(error.message),
	});

	const columns = [
		{
			name: "Option",
			selector: (row) => row?.name,
			compact: true,
		},
		{
			name: "Amount",
			selector: (row) => renderCurrency(row?.amount),
			compact: true,
		},
		{
			name: "",
			cell: (row) => (
				<div className="flex gap-4">
					<EditIcon
						className="cursor-pointer stroke-primary text-primary"
						onClick={() => {
							setSelectedOption(row);
							setIsEditModalOpen(true);
						}}
					/>
					<DeleteIcon
						className="cursor-pointer stroke-primary text-primary"
						onClick={() => {
							renderConfirmDialogue().then((result) => {
								if (result.isConfirmed) mutate(row?.id);
							});
						}}
					/>
				</div>
			),
			width: "50px",
			compact: true,
		},
	];
	return (
		<>
			<CustomDataTable columns={columns} data={data.data} />
			<Modal isOpen={openModal} setIsOpen={setOpenModal} title="Add Delivery Option" size="xsm">
				<AddDelivery refetch={refetch} setOpenModal={setOpenModal} />
			</Modal>
			<Modal isOpen={isEditModalOpen} setIsOpen={setIsEditModalOpen} title="Edit Delivery Option" size="xsm">
				<AddDelivery option={selectedOption} refetch={refetch} setOpenModal={setIsEditModalOpen} />
			</Modal>
		</>
	);
};

export default DeliveryOption;
