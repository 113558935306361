import customersIcon from "src/assets/images/totalcustomers.png";
import { CustomDataTable, Modal } from "src/components";
import { renderCurrency, renderNumber } from "src/assets/functions";
import { getNewCustomerMetricsWithNoRecentOrders } from "src/api";
import { useQuery } from "@tanstack/react-query";
import { CustomerDetails } from "src/modals/customers";
import { useState } from "react";
import { UserIcon } from "src/assets/svg";

const CustomerWithNoRecentOrder = ({ customerOrders }) => {
	const [isCustomerModalOpen, setIsCustomerModalOpen] = useState(false);
	const [selectedcustomerdetails, setselectedcustomerdetails] = useState(null);

	const { data: newcustomers } = useQuery({
		queryKey: ["getNewCustomerMetricsWithNoRecentOrders"],
		queryFn: getNewCustomerMetricsWithNoRecentOrders,
		suspense: true,
	});

	const columns = [
		{
			name: "Name",
			selector: (row) => row?.full_name,
		},
		{
			name: "Email Address",
			selector: (row) => row?.email,
		},
		{
			name: "Phone No",
			selector: (row) => row?.phone,
		},
		{
			name: "Customer ID",
			selector: (row) => row?.customerID,
		},
		{
			name: "Discount",
			selector: (row) => renderCurrency(row?.discount),
		},
		{
			name: "",
			cell: (row) => (
				<div className="flex gap-4">
					<UserIcon
						className="cursor-pointer stroke-primary text-primary"
						onClick={() => {
							setselectedcustomerdetails(row);
							setIsCustomerModalOpen(true);
						}}
					/>
				</div>
			),
			width: "50px",
			compact: true,
		},
	];

	return (
		<div className="">
			<div className="flex justify-between">
				<h2 className="my-[30px] text-black text-[2rem] font-bold">Customer Order</h2>
			</div>
			<div className="grid grid-cols-2 mb-8 border border-gray-300 divide-x divide-gray-300 rounded-lg">
				<div className="px-4 py-5">
					<div className="flex justify-between">
						<p className="text-[1.4rem] text-darkText font-medium">Total Customers</p>
						{<img src={customersIcon} alt="" />}
					</div>
					<p className="text-[2rem] leading-[3rem] font-bold">
						{renderNumber(customerOrders?.data?.totalCustomers[0]?.total_customers)}
					</p>
				</div>
				<div className="px-4 py-5">
					<div className="flex justify-between">
						<p className="text-[1.4rem] text-darkText font-medium">New Customers</p>
						{<img src={customersIcon} alt="" />}
					</div>
					<p className="text-[2rem] leading-[3rem] font-bold">
						{renderNumber(customerOrders?.data?.newCustomers[0]?.newCustomersCount)}
					</p>
				</div>
			</div>
			<div className="flex justify-between mt-[100px] px-[10rem]">
				<div>
					<h2 className="mb-[10px] font-[700] text-[18px]">Orders</h2>
					{customerOrders?.data?.topCustomers?.data.map((topCustomers) => {
						return (
							<div key={topCustomers?.id}>
								<p className="font-[300] text-[14px] text-[#47505B] mb-[10px]">
									{topCustomers?.full_name}
									<span>({topCustomers?.customer_total_orders})</span>
								</p>
							</div>
						);
					})}
				</div>
				<div>
					<h2 className="mb-[10px] font-[700] text-[18px]">Sales</h2>
					{customerOrders?.data?.topCustomers?.data.map((topCustomers) => {
						return (
							<div key={topCustomers?.id}>
								<p className="font-[300] text-[14px] text-[#47505B] mb-[10px]">
									{topCustomers?.total_order_amount
										? renderCurrency(topCustomers?.total_order_amount)
										: "Not Available"}
								</p>
							</div>
						);
					})}
				</div>
				<div>
					<h2 className="mb-[10px] font-[700] text-[18px]">Revenue</h2>
					{customerOrders?.data?.topCustomers?.data.map((topCustomers) => {
						return (
							<div key={topCustomers?.id}>
								<p className="font-[300] text-[14px] text-[#47505B] mb-[10px]">
									{topCustomers?.total_order_amount
										? renderCurrency(topCustomers?.total_revenue)
										: "Not Available"}
								</p>
							</div>
						);
					})}
				</div>
			</div>
			<div className="mt-[80px]">
				<CustomDataTable columns={columns} data={newcustomers?.data?.newCustomers?.data} />
			</div>

			<Modal
				isOpen={isCustomerModalOpen}
				setIsOpen={setIsCustomerModalOpen}
				title="Customer Details"
				size="large"
			>
				<CustomerDetails customerdetails={selectedcustomerdetails} />
			</Modal>
		</div>
	);
};
export default CustomerWithNoRecentOrder;
