import { useMutation, useQuery } from "@tanstack/react-query";
import { ErrorMessage, Formik } from "formik";
import React from "react";
import { createRoute, editRoute, getAllStaff } from "src/api";
import { renderErrorMessage, renderSuccessMessage } from "src/assets/functions";
import { CustomTextInput, CustomSelectInput } from "src/components";
import * as Yup from "yup";

const AddRoute = ({ selectedroutedetails, refetch, setOpenModal }) => {
	const { data: allStaff } = useQuery({
		queryKey: ["getAllStaff"],
		queryFn: () => getAllStaff(),
		suspense: true,
	});

	const { mutate, isLoading } = useMutation(selectedroutedetails ? editRoute : createRoute, {
		onSuccess: (data) => {
			renderSuccessMessage(data.message).then(() => {
				refetch();
				setOpenModal(false);
			});
		},
		onError: (error) => {
			renderErrorMessage(error.message);
		},
	});

	const initialValues = selectedroutedetails
		? {
			id: selectedroutedetails.id,
			route_name: selectedroutedetails.route_name,
			staff_id: selectedroutedetails.staff_id,
		}
		: { route_name: "", staff_id: "" };

	const validationSchema = Yup.object().shape({
		route_name: Yup.string().required("Route name is required"),
		staff_id: Yup.string().required("Staff is required"),
	});

	return (
		<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={(values) => mutate(values)}>
			{({ values, handleChange, handleSubmit }) => (
				<form onSubmit={handleSubmit} className="flex flex-col">
					<div className="flex flex-col gap-10 my-10 mb-20">
						<CustomTextInput
							label="Route Name"
							name="route_name"
							onChange={handleChange}
							value={values.route_name}
							placeholder="Route Name"
						/>
						<ErrorMessage name="route_name" component="div" className="text-red-500 text-[1rem]" />
						<CustomSelectInput
							label="Staff"
							name="staff_id"
							onChange={handleChange}
							value={values.isLoop}
							placeholder="Select Staff"
							options={allStaff.data.map((staff) => ({
								value: staff.id,
								label: staff.fullName,
							}))}
						/>
						<ErrorMessage name="staff_id" component="div" className="text-red-500 text-[1rem]" />
					</div>
					<div className="flex gap-3 ml-auto">
						<button
							className="btn"
							type="button" onClick={() => setOpenModal(false)}
						>
							Cancel
						</button>
						<button className="btn-primary" disabled={isLoading}>
							{selectedroutedetails ? "Edit" : "Create"}
						</button>
					</div>
				</form>
			)}
		</Formik>
	);
};

export default AddRoute;
