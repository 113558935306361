import { useMutation } from "@tanstack/react-query";
import { Formik } from "formik";
import React from "react";
import { createProductGroup, editProductGroup } from "src/api";
import { renderSuccessMessage } from "src/assets/functions";
import { CustomTextInput } from "src/components";
import * as Yup from "yup";

const AddNewItem = ({ setOpenModal, refetch, selectedGroup }) => {
	const initialValues = selectedGroup
		? {
				id: selectedGroup?.id,
				group_name: selectedGroup?.group_name,
		  }
		: { group_name: "" };

	const validationSchema = Yup.object().shape({
		group_name: Yup.string().required("Product name is required"),
	});

	const { mutate, isLoading } = useMutation(selectedGroup ? editProductGroup : createProductGroup, {
		onSuccess: ({ message }) =>
			renderSuccessMessage(message).then(() => {
				refetch();
				setOpenModal(false);
			}),
	});

	return (
		<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={(values) => mutate(values)}>
			{({ values, handleChange, handleSubmit }) => (
				<form onSubmit={handleSubmit} className="flex flex-col">
					<div className="my-10 mb-20">
						<CustomTextInput
							label="Product Name"
							name="group_name"
							onChange={handleChange}
							value={values.group_name}
						/>
					</div>
					<div className="flex gap-3 ml-auto">
						<button className="btn" type="button" onClick={() => setOpenModal(false)}>
							Cancel
						</button>
						<button className="btn-primary" disabled={isLoading}>
							Save
						</button>
					</div>
				</form>
			)}
		</Formik>
	);
};

export default AddNewItem;
