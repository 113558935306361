import { ErrorMessage } from "formik";
import React from "react";
import { DebounceInput } from "react-debounce-input";

const CustomTextInput = ({ label, type = "text", containerClassName = "", ...rest }) => {
	return (
		<div className={`flex flex-col gap-1 ${containerClassName}`}>
			<label htmlFor={label} className={`text-[1.4rem] text-lightText font-medium mb-[10px]`}>
				{label}
			</label>
			<DebounceInput
				type={type}
				id={label}
				debounceTimeout={300}
				className={`border border-lightGray rounded-md p-[10px] text-[1.3rem] placeholder:text-[1.3rem] text-lightText ${rest.className}`}
				{...rest}
			/>
			{!!rest["name"] && <ErrorMessage name={rest["name"]} component="div" className="text-red-500 text-[1rem]" />}
		</div>
	);
};

export const CustomTextInputWithoutErrorMessage = ({ label, type = "text", containerClassName = "", ...rest }) => {
	return (
		<div className={`flex flex-col gap-1 ${containerClassName}`}>
			<label htmlFor={label} className={`text-[1.4rem] text-lightText font-medium mb-[10px]`}>
				{label}
			</label>
			<DebounceInput
				type={type}
				id={label}
				debounceTimeout={300}
				className={`border border-lightGray rounded-md p-[10px] text-[1.3rem] placeholder:text-[1.3rem] text-lightText ${rest.className}`}
				{...rest}
			/>
		</div>
	);
};

export default CustomTextInput;
