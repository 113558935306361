import React, { useState } from "react";
import { DeleteIcon, EditIcon, UserIcon, WalletIcon } from "src/assets/svg";
import { CustomDataTable, FilterByDate, IconInput, Modal } from "src/components";
import { AddCustomer, CustomerDetails, FundWallet } from "src/modals/customers";
import { deleteCustomer, exportCustomer, getallcustomers } from "src/api";
import { useMutation, useQuery } from "@tanstack/react-query";
import { renderConfirmDialogue, renderCurrency, renderSuccessMessage } from "src/assets/functions";
import { useSearchParams } from "react-router-dom";
import { useProtectedRoutesContext } from "src/context/ProtectedRoutes";
import Popup from "reactjs-popup";
import { FilterDateTrigger } from "src/components/FilterByDate";

const Customers = () => {
	const { user } = useProtectedRoutesContext();
	const [searchParams, setSearchParams] = useSearchParams();

	const page = searchParams.get("page") ?? 1;
	const per_page = searchParams.get("per_page") ?? 50;

	const startDate = searchParams.get("startDate");
	const endDate = searchParams.get("endDate");

	const [isCustomerModalOpen, setIsCustomerModalOpen] = useState(false);
	const [isAddCustomerModalOpen, setIsAddCustomerModalOpen] = useState(false);
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	const [isFundModalOpen, setIsFundModalOpen] = useState(false);
	const [selectedcustomer, setselectedcustomer] = useState(null);
	const [selectedcustomerdetails, setselectedcustomerdetails] = useState(null);
	const [searchdata, setSearchdata] = useState("");

	const { data, refetch } = useQuery({
		queryKey: ["getallcustomers", searchdata, page, per_page],
		queryFn: () => getallcustomers({ search_text: searchdata, page, per_page }),
		suspense: true,
	});

	const { mutate } = useMutation(deleteCustomer, {
		onSuccess: ({ message }) => renderSuccessMessage(message).then(() => refetch()),
	});

	const { isFetching } = useQuery({
		queryKey: ["export-customers", startDate, endDate],
		queryFn: () => exportCustomer({ startDate, endDate }),
		onSuccess: () => {
			searchParams.delete("startDate");
			searchParams.delete("endDate");
			setSearchParams(searchParams);
		},
		enabled: !!startDate && !!endDate,
	});

	const columns = [
		{
			name: "Customer Name",
			selector: (row) => row?.full_name,
			compact: true,
		},
		{
			name: "Wallet Balance",
			selector: (row) => renderCurrency(row?.wallet),
			compact: true,
		},
		{
			name: "Phone Number",
			selector: (row) => row?.phone,
			compact: true,
		},
		{
			name: "Email",
			selector: (row) => row?.email,
			compact: true,
		},
		{
			name: "",
			cell: (row) => (
				<div className="flex gap-4">
					{user?.role === "admin" && (
						<WalletIcon
							className="cursor-pointer stroke-primary text-primary"
							onClick={() => {
								setselectedcustomerdetails(row);
								setIsFundModalOpen(true);
							}}
						/>
					)}
					<UserIcon
						className="cursor-pointer stroke-primary text-primary"
						onClick={() => {
							setselectedcustomerdetails(row);
							setIsCustomerModalOpen(true);
						}}
					/>
					<EditIcon
						className="cursor-pointer stroke-primary text-primary"
						onClick={() => {
							setselectedcustomer(row);
							setIsEditModalOpen(true);
						}}
					/>
					<DeleteIcon
						className="cursor-pointer stroke-primary text-primary"
						onClick={() =>
							renderConfirmDialogue().then((result) => {
								if (result.isConfirmed) mutate(row?.id);
							})
						}
					/>
				</div>
			),
			width: "100px",
			compact: true,
		},
	];

	const handleInputChange = (event) => setSearchdata(event.target.value);

	return (
		<>
			<div>
				<div className="flex items-center justify-between my-[2rem]">
					<h1 className="text-black text-[2rem] font-bold">Customers</h1>
					<div className="flex items-center gap-5 border-gray-300">
						<IconInput
							className="w-[250px]"
							placeholder="Search"
							value={searchdata} // Bind the input value to the state variable
							onChange={handleInputChange} // Set the onChange handler
						/>
					</div>
					<div className="flex items-center gap-3">
						<button className="btn-primary" onClick={() => setIsAddCustomerModalOpen(true)}>
							Add Customer
						</button>
						<Popup
							trigger={<FilterDateTrigger name={"Export"} isLoading={isFetching} />}
							arrow={false}
							closeOnDocumentClick
							className="date"
							position={["bottom left"]}
							contentStyle={{
								width: "fit-content",
								padding: 0,
							}}
						>
							<FilterByDate />
						</Popup>
					</div>
				</div>
				<CustomDataTable columns={columns} data={data?.data?.data} paginationTotalRows={data?.data?.total} />
			</div>
			<Modal isOpen={isFundModalOpen} setIsOpen={setIsFundModalOpen} title="Fund Customer Wallet" size="xsm">
				<FundWallet customerdetails={selectedcustomerdetails} setIsOpen={setIsFundModalOpen} refetch={refetch} />
			</Modal>
			<Modal isOpen={isCustomerModalOpen} setIsOpen={setIsCustomerModalOpen} title="Customer Details" size="large">
				<CustomerDetails customerdetails={selectedcustomerdetails} />
			</Modal>
			<Modal isOpen={isEditModalOpen} setIsOpen={setIsEditModalOpen} title="Edit Customer Details" size="large">
				<AddCustomer customer={selectedcustomer} setIsOpen={setIsEditModalOpen} refetch={refetch} />
			</Modal>
			<Modal isOpen={isAddCustomerModalOpen} setIsOpen={setIsAddCustomerModalOpen} title="Add Customer" size="large">
				<AddCustomer setIsOpen={setIsAddCustomerModalOpen} refetch={refetch} />
			</Modal>
		</>
	);
};

export default Customers;
