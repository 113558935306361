import { useQuery } from "@tanstack/react-query";
import CustomDataTable from "../CustomDataTable";
import { getcustomerOrder } from "src/api";
import { format, parseISO } from "date-fns";
import { renderCurrency } from "src/assets/functions";

const CustomerPaidOrders = ({ customerdetails }) => {
	const { data } = useQuery({
		queryKey: ["getcustomerOrder", customerdetails?.id, ""],
		queryFn: () => getcustomerOrder(customerdetails?.id, ""),
		suspense: true,
	});

	const orders = data?.data?.allPaid;

	const columns = [
		{
			name: "ID",
			selector: (row) => row?.serial_number,
			width: "90px",
			compact: true,
		},
		{
			name: "Order Date",
			selector: (row) => row?.dateTimeIn && format(parseISO(row?.dateTimeIn), "dd MMM yyyy"),
			compact: true,
		},
		{
			name: "Ready By",
			selector: (row) => (row?.dateTimeOut ? format(parseISO(row?.dateTimeOut), "dd MMM yyyy HH:mm") : row?.dateTimeOut),
			compact: true,
		},
		{
			name: "Order",
			cell: (row) => (
				<div className="text-[1.2rem] font-medium leading-relaxed w-[95%] py-[7px] capitalize">
					{row?.transactions?.reduce(
						(string, { item }) => string.concat(item?.brand, " ", item?.description || "", " ", item?.product?.name, ", "),
						""
					)}
				</div>
			),
			wrap: true,
			grow: 3,
			compact: true,
		},
		{
			name: "Item No",
			selector: (row) => row?.itemsCount,
			width: "80px",
			compact: true,
		},
		{
			name: "Rack",
			selector: (row) => (!!row.store_rack ? row.store_rack : "N/A"),
			width: "80px",
			compact: true,
		},
		{
			name: "Total",
			selector: (row) => renderCurrency(row?.bill),
			compact: true,
		},
	];
	const conditionalRowStyles = [
		{
			when: (row) => row.status === "deleted",
			style: {
				textDecoration: "line-through",
			},
		},
	];

	return (
		<>
			<CustomDataTable columns={columns} data={orders} conditionalRowStyles={conditionalRowStyles} />
		</>
	);
};
export default CustomerPaidOrders;
