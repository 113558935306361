import { format } from "date-fns";
import { Formik } from "formik";
import React from "react";
import { CustomDatePicker, CustomSelectInput, CustomTextArea, CustomTextInput } from "src/components";
import * as Yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { addcustomer, editcustomer } from "src/api";
import { renderErrorMessage, renderSuccessMessage } from "src/assets/functions";
import { useProtectedRoutesContext } from "src/context/ProtectedRoutes";

const AddCustomer = ({ customer, setIsOpen, refetch }) => {
	const { user } = useProtectedRoutesContext();

	const initialValues = customer
		? {
				id: customer?.uuid,
				full_name: customer?.full_name,
				secondary_phone: customer?.secondary_phone,
				phone: customer?.phone,
				street_address: customer?.street_address,
				email: customer?.email,
				city: customer?.city,
				gender: customer?.gender,
				notes: customer?.notes,
				birthday: customer?.birthday,
				shirt_preference: customer?.shirt_preference,
				trouser_preference: customer?.trouser_preference,
				starch: customer?.starch,
				default_payment: customer?.default_payment,
				private_notes: customer?.private_notes,
				discount: customer?.discount ?? 0,
		  }
		: {
				full_name: "",
				secondary_phone: "",
				phone: "",
				street_address: "",
				email: "",
				city: "",
				gender: "",
				notes: "",
				birthday: null,
				shirt_preference: "",
				trouser_preference: "",
				starch: "",
				default_payment: "",
				discount: 0,
		  };

	const validationSchema = Yup.object().shape({
		full_name: Yup.string().required("Customer name is required"),
		secondary_phone: Yup.string().nullable(),
		phone: Yup.string().required("Customer phone number is required"),
		street_address: Yup.string().required("Customer street_address is required"),
		email: Yup.string().nullable(),
		city: Yup.string().required("Customer city is required"),
		gender: Yup.string().required("Customer gender is required"),
		notes: Yup.string().nullable(),
		birthday: Yup.string().nullable(),
		discount: Yup.number().min(0, "Minumum allowed discount is 0"),
	});

	const { mutate, isLoading } = useMutation(customer ? editcustomer : addcustomer, {
		onSuccess: (data) => {
			renderSuccessMessage(data.message).then(() => {
				refetch();
				setIsOpen(false);
			});
		},
		onError: (error) => {
			renderErrorMessage(error.message);
		},
	});

	return (
		<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={(values) => mutate(values)}>
			{({ values, handleChange, handleSubmit, setFieldValue }) => (
				<form onSubmit={handleSubmit} className="flex flex-col">
					<div className="grid max-[700px]:grid-cols-2 grid-cols-3 gap-4 mb-5">
						<CustomTextInput label="Name" name="full_name" onChange={handleChange} value={values.full_name} placeholder="Enter Name" />
						<CustomTextInput
							label="Phone Number"
							name="phone"
							onChange={handleChange}
							value={values.phone}
							placeholder="Enter Phone Number"
						/>
						<CustomTextInput
							label="Secondary Phone"
							name="secondary_phone"
							onChange={handleChange}
							value={values.secondary_phone}
							placeholder="Enter Secondary Phone"
						/>
						<CustomTextInput label="Email" name="email" onChange={handleChange} value={values.email} placeholder="Enter Email" />
						<CustomTextInput
							label="Street Address"
							name="street_address"
							onChange={handleChange}
							value={values.street_address}
							placeholder="Enter Address"
						/>
						<CustomTextInput label="City" name="city" onChange={handleChange} value={values.city} placeholder="Enter City" />
						<CustomSelectInput
							label="Gender"
							name="gender"
							onChange={handleChange}
							value={values.gender}
							placeholder="Enter Gender"
							options={[
								{ value: "male", label: "Male" },
								{ value: "female", label: "Female" },
							]}
						/>
						<CustomDatePicker
							label="Birthday"
							name="birthday"
							className="p-[7px] border border-lightGray rounded-md w-full text-[1.4rem] text-lightText font-medium"
							selected={new Date(values.birthday ?? null)}
							onChange={(date) => setFieldValue("birthday", format(date, "yyyy-MM-dd"))}
						/>
						{user?.role === "admin" && (
							<CustomTextInput
								label="Discount (%)"
								name="discount"
								onChange={handleChange}
								value={values.discount}
								placeholder="Enter Discount"
								type="number"
								min={0}
								max={100}
							/>
						)}

						<CustomTextArea
							containerClassName="max-[700px]:col-span-2 col-span-3"
							label="Private Notes"
							name="private_notes"
							value={values.private_notes}
							onChange={(event) => setFieldValue("private_notes", event.target.value)}
						/>

						<CustomTextArea
							containerClassName="max-[700px]:col-span-2 col-span-3"
							label="Notes"
							name="notes"
							value={values.notes}
							onChange={(event) => setFieldValue("notes", event.target.value)}
						/>
					</div>

					<h2 className="my-[25px] text-[#0132ad]">Order Preferences</h2>

					<div className="grid grid-cols-3 gap-4 mb-5">
						<CustomSelectInput
							label="Shirt"
							name="shirt_preference"
							onChange={handleChange}
							placeholder="Select Preference"
							options={[
								{ value: "fold", label: "Fold" },
								{ value: "hand", label: "Hang" },
							]}
						/>
						<CustomSelectInput
							label="Trouser"
							name="trouser_preference"
							onChange={handleChange}
							placeholder="Select Preference"
							options={[
								{ value: "fold", label: "Fold" },
								{ value: "hand", label: "Hang" },
							]}
						/>
						<CustomSelectInput
							label="Starch"
							name="starch"
							onChange={handleChange}
							placeholder="Select Preference"
							options={[
								{ value: "no", label: "No" },
								{ value: "yes", label: "Yes" },
							]}
						/>
						<CustomSelectInput
							label="Default Payment Type"
							name="default_payment"
							onChange={handleChange}
							placeholder="Select Preference"
							options={[
								{ value: "cash", label: "Cash" },
								{ value: "card", label: "Card" },
								{ value: "cheque", label: "Cheque" },
								{ value: "transfer", label: "Transfer" },
								{ value: "wallet", label: "Wallet" },
							]}
						/>
					</div>

					<div className="flex gap-3 ml-auto">
						<button className="btn" onClick={() => setIsOpen(false)}>
							Cancel
						</button>
						<button type="submit" className="btn-primary" disabled={isLoading}>
							{customer ? "Edit" : "Add"}
						</button>
					</div>
				</form>
			)}
		</Formik>
	);
};

export default AddCustomer;
