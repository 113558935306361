import { useMutation } from "@tanstack/react-query";
import { Formik } from "formik";
import { editOrderRack, scanOrder } from "src/api";
import { renderSuccessMessage } from "src/assets/functions";
import { CustomTextInput } from "src/components";
import { useProtectedRoutesContext } from "src/context/ProtectedRoutes";
import * as Yup from "yup";

const SetTrack = ({ order, setIsOpen, refetch, rackOnly }) => {
	const { user } = useProtectedRoutesContext();
	const initialValues = {
		id: order.id,
		store_rack: "",
	};
	const validationSchema = Yup.object().shape({
		store_rack: Yup.string().required("Please provide a rack for this garment"),
	});

	const { mutateAsync: editMutate, isLoading: isEditLoading } = useMutation(editOrderRack);

	const { mutateAsync: scanMutate, isLoading: isScanLoading } = useMutation(scanOrder);

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={async (values) => {
				await editMutate(values);
				if (!rackOnly) {
					await scanMutate({
						departmentId: user?.department?.id,
						orderId: order?.id,
						stage: "scan-in",
					});
					renderSuccessMessage("Order moved to completed").then(() => {
						setIsOpen(false);
						refetch();
					});
				} else {
					renderSuccessMessage("Order assigned to rack").then(() => {
						setIsOpen(false);
						refetch();
					});
				}
			}}
		>
			{({ values, handleSubmit, handleChange }) => (
				<form onSubmit={handleSubmit}>
					<CustomTextInput
						label="Rack Number"
						name="store_rack"
						onChange={handleChange}
						value={values.store_rack}
						containerClassName="mt-[30px]"
					/>
					<button className="w-full mt-4 btn-primary" disabled={isEditLoading || isScanLoading}>
						Assign Rack
					</button>
				</form>
			)}
		</Formik>
	);
};

export default SetTrack;
