import { CustomDataTable, Modal } from "src/components";
import { deleteLocation, getAllLocation } from "src/api";
import { useMutation, useQuery } from "@tanstack/react-query";
import { DeleteIcon, EditIcon } from "src/assets/svg";
import AddLocation from "src/modals/settings/AddLocation";
import { useState } from "react";
import { renderConfirmDialogue, renderSuccessMessage } from "src/assets/functions";

const Locations = ({ openModal, setOpenModal }) => {
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	const [selectedLocation, setSelectedLocation] = useState(null);

	const { data, refetch } = useQuery({
		queryKey: ["getAllLocation"],
		queryFn: () => getAllLocation(),
		suspense: true,
	});

	const { mutate } = useMutation(deleteLocation, {
		onSuccess: ({ message }) => renderSuccessMessage(message).then(() => refetch()),
	});

	const columns = [
		{
			name: "Location Name",
			selector: (row) => row?.locationName,
			width: "200px",
			compact: true,
		},
		{
			name: "Code",
			selector: (row) => row?.store_code,
			compact: true,
		},
		{
			name: "Address",
			selector: (row) => row?.address,
			width: "200px",
			compact: true,
		},
		{
			name: "Phone No",
			selector: (row) => row?.phoneNumber,
			compact: true,
		},
		{
			name: "Route",
			selector: (row) => row?.route_name,
			compact: true,
		},
		{
			name: "",
			cell: (row) => (
				<div className="flex gap-4">
					<EditIcon
						className="cursor-pointer stroke-primary text-primary"
						onClick={() => {
							setIsEditModalOpen(true);
							setSelectedLocation(row);
						}}
					/>
					<DeleteIcon
						className="cursor-pointer stroke-primary text-primary"
						onClick={() =>
							renderConfirmDialogue("Do you want to delete this location?").then((result) => {
								if (result.isConfirmed) mutate(row?.id);
							})
						}
					/>
				</div>
			),
			width: "50px",
			compact: true,
		},
	];

	return (
		<>
			<CustomDataTable columns={columns} data={data?.data} pointerOnHover />
			<Modal isOpen={openModal} setIsOpen={setOpenModal} title="Add Location">
				<AddLocation setOpenModal={setOpenModal} refetch={refetch} />
			</Modal>
			<Modal isOpen={isEditModalOpen} setIsOpen={setIsEditModalOpen} title="Edit Location">
				<AddLocation setOpenModal={setIsEditModalOpen} refetch={refetch} selectedLocation={selectedLocation} />
			</Modal>
		</>
	);
};

export default Locations;
