import React from "react";
import { DebounceInput } from "react-debounce-input";

const IconInput = ({ type = "text", icon, ...rest }) => {
	return (
		<div className={`flex items-center gap-3 px-[15px] py-[10px] border border-gray-300 rounded-md ${rest.containerClassName}`}>
			{icon}
			<DebounceInput
				type={type}
				debounceTimeout={1000}
				{...rest}
				className={`text-[1.3rem] bg-transparent placeholder:text-[1.3rem] text-lightText outline-0 ${rest.className}`}
			/>
		</div>
	);
};

export default IconInput;
