import { useMutation } from "@tanstack/react-query";
import { Formik } from "formik";
import React from "react";
import { createDiscountType, editDiscountType } from "src/api";
import { renderSuccessMessage } from "src/assets/functions";
import { CustomTextInput } from "src/components";
import * as Yup from "yup";

const AddDiscount = ({ setOpenModal, refetch, selectedDiscount }) => {
	const initialValues = {
		id: selectedDiscount?.id,
		type: selectedDiscount?.type ?? "",
		percentage: selectedDiscount?.percentage ?? "",
	};
	const validationSchema = Yup.object().shape({
		type: Yup.string().required(),
		percentage: Yup.string().required(),
	});

	const { mutate, isLoading } = useMutation(selectedDiscount ? editDiscountType : createDiscountType, {
		onSuccess: ({ message }) =>
			renderSuccessMessage(message).then(() => {
				refetch();
				setOpenModal(false);
			}),
	});
	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values) => {
				mutate(values);
			}}
		>
			{({ values, handleChange, handleSubmit }) => (
				<form onSubmit={handleSubmit} className="flex flex-col">
					<div className="flex flex-col gap-10 my-10 mb-20 ">
						<CustomTextInput label="Name" name="type" onChange={handleChange} value={values.type} placeholder="Enter Discount Name..." />
						<CustomTextInput
							label="Multiplier"
							name="percentage"
							onChange={handleChange}
							value={values.percentage}
							placeholder="Enter multiplier..."
						/>
					</div>
					<div className="flex gap-3 ml-auto">
						<button className="btn" type="button" onClick={() => setOpenModal(false)}>
							Cancel
						</button>
						<button className="btn-primary" disabled={isLoading}>
							{selectedDiscount ? "Edit" : "Add"}
						</button>
					</div>
				</form>
			)}
		</Formik>
	);
};

export default AddDiscount;
