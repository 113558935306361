import { ErrorMessage, Formik } from "formik";
import React from "react";
import { CustomSelectInput, CustomTextInput } from "src/components";
import * as Yup from "yup";
import Select from "react-select";
import { useMutation } from "@tanstack/react-query";
import { renderErrorMessage, renderSuccessMessage } from "src/assets/functions";
import { createDepartment, editDepartment } from "src/api";

const AddDepartment = ({ editdepartment, refetch, setOpenModal, getAllDefaultOptions }) => {
	const { mutate, isLoading } = useMutation(editdepartment ? editDepartment : createDepartment, {
		onSuccess: (data) => {
			renderSuccessMessage(data.message).then(() => {
				refetch();
				setOpenModal(false);
			});
		},
		onError: (error) => {
			renderErrorMessage(error.message);
		},
	});

	const initialValues = editdepartment
		? {
				id: editdepartment.id,
				name: editdepartment.name,
				scan_in_out: editdepartment.scan_in_out,
				allow_batch_job: editdepartment.allow_batch_job,
				default_options: editdepartment?.default_options?.split(",").map((option) => ({
					value: option,
					label: option,
				})),
				action_options: editdepartment?.action_options?.split(",").map((option) => ({
					value: option,
					label: option,
				})),
		  }
		: {
				name: "",
				scan_in_out: "",
				action_options: [],
				default_options: [],
				allow_batch_job: "0",
		  };

	const validationSchema = Yup.object().shape({
		name: Yup.string().required("Department name is required"),
		scan_in_out: Yup.string().required("This field is required"),
		allow_batch_job: Yup.string().required("This field is required").oneOf(["0", "1"]),
		default_options: Yup.array().length(1, "A department must have one default option"),
		action_options: Yup.array().min(1, "Select Actions for this departments"),
	});

	const options = getAllDefaultOptions?.map((option) => ({
		value: option.name,
		label: option.name,
	}));

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values) => {
				const data = { ...values };
				data.action_options = data.action_options
					.reduce((prev, current) => prev.concat(current.label, ","), "")
					.slice(0, -1);
				data.default_options = data.default_options
					.reduce((prev, current) => prev.concat(current.label, ","), "")
					.slice(0, -1);
				data.allow_batch_job = data.allow_batch_job === "1";
				mutate(data);
			}}
		>
			{({ values, handleChange, handleSubmit, setFieldValue }) => (
				<form onSubmit={handleSubmit} className="flex flex-col">
					<div className="flex flex-col py-10 gap-14">
						<CustomTextInput
							label="Department Name"
							name="name"
							onChange={handleChange}
							value={values.name}
							placeholder="Department Name"
						/>
						<CustomSelectInput
							label="Scan Out"
							name="scan_in_out"
							onChange={handleChange}
							value={values.scan_in_out}
							placeholder="Select"
							options={[
								{ value: "1", label: "Yes" },
								{ value: "0", label: "No" },
							]}
						/>
						<CustomSelectInput
							label="Allow Batch Scan In/Out"
							name="allow_batch_job"
							onChange={handleChange}
							value={values.allow_batch_job}
							placeholder="Select"
							options={[
								{ value: "1", label: "Yes" },
								{ value: "0", label: "No" },
							]}
						/>
						<div>
							<span className="text-[1.5rem] text-[#47505B] font-normal mb-0">Action options</span>
							<Select
								closeMenuOnSelect={false}
								value={values.action_options}
								onChange={(value) => setFieldValue("action_options", value)}
								isMulti
								options={options}
							/>
						</div>
						<ErrorMessage name="action_options" component="div" className="text-red-500 text-[1rem]" />
						<span className="text-[1.5rem] text-[#47505B] font-normal mb-0">Default options</span>
						<Select
							name="default_options"
							closeMenuOnSelect={false}
							value={values.default_options}
							onChange={(value) => setFieldValue("default_options", value)}
							defaultValue={values.default_options}
							isMulti
							options={options}
						/>
						<ErrorMessage name="default_options" component="div" className="text-red-500 text-[1rem]" />
					</div>
					<div className="flex gap-3 ml-auto">
						<button className="btn" type="button" onClick={() => setOpenModal(false)}>
							Cancel
						</button>
						<button className="btn-primary" disabled={isLoading}>
							Save
						</button>
					</div>
				</form>
			)}
		</Formik>
	);
};

export default AddDepartment;
