import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useProSidebar } from "react-pro-sidebar";
import { confirmClockin, staffClockIn, staffClockOut } from "src/api";
import { renderErrorMessage, renderSuccessMessage } from "src/assets/functions";
import { MenuIcon } from "src/assets/svg";
import { CustomPopup, Modal } from "src/components";
import { useProtectedRoutesContext } from "src/context/ProtectedRoutes";
import SwitchUser from "src/modals/SwitchUser";
import avatar from "src/assets/images/avatar.png";
import ClockInNow from "src/modals/ClockInNow";

const TopNav = () => {
	const { toggleSidebar } = useProSidebar();
	const { user, setUser, setToken } = useProtectedRoutesContext();

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isClockedinModal, setIsClockedinModal] = useState(false);

	const { data: clockstatus, refetch } = useQuery({
		queryKey: ["confirmClockin", user?.uuid],
		queryFn: () => confirmClockin(user?.uuid),
		suspense: true,
	});

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		clockstatus.clockin ? setIsClockedinModal(false) : setIsClockedinModal(true);
	});

	const { mutate, isLoading } = useMutation(clockstatus?.clockin ? staffClockOut : staffClockIn, {
		onSuccess: ({ message }) => renderSuccessMessage(message).then(() => refetch()),
		onError: (error) => renderErrorMessage(error.message),
	});

	return (
		<>
			<div className="bg-white border-b border-b-lightGray flex items-center justify-between py-[1.45rem]">
				<h2 className="text-black text-[2rem] font-bold">Welcome Back, {user?.fullName}👋🏻</h2>

				<div className="flex items-center gap-6">
					<button className="small-btn-primary" onClick={() => setIsModalOpen(true)}>
						Switch User
					</button>
					<CustomPopup
						trigger={
							<button className="border-none outline-none" onClick={() => setIsModalOpen(true)}>
								<img src={avatar} alt="user-avatar" className="w-[40px]" />
							</button>
						}
						arrow={false}
						closeOnDocumentClick
						position={["bottom right"]}
						width={"140px"}
						nested
						childrenArray={[
							{
								text: clockstatus?.clockin ? "Clock Out" : "Clock In",
								onClick: isLoading ? null : () => mutate({ id: user?.id }),
							},
							{
								text: <div className="font-semibold text-red-500">Log Out</div>,
								onClick: () => {
									setToken(null);
									setUser(null);
									localStorage.clear();
									window.location.reload();
								},
							},
						]}
					/>
					<MenuIcon className="cursor-pointer min-[1101px]:hidden" onClick={() => toggleSidebar()} />
				</div>
			</div>
			<Modal isOpen={isModalOpen} setIsOpen={setIsModalOpen} title="Switch User" size="xsm">
				<SwitchUser setIsModalOpen={setIsModalOpen} />
			</Modal>
			<Modal isOpen={isClockedinModal} setIsOpen={setIsClockedinModal} title="Please Clock In to Proceed" size="xsm">
				<ClockInNow setIsModalOpen={setIsClockedinModal} refetch={refetch} />
			</Modal>
		</>
	);
};

export default TopNav;
