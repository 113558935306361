import { ErrorMessage } from "formik";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CustomDatePicker = ({ label, containerClassName, name, ...rest }) => {
	return (
		<div className={`flex flex-col gap-1 ${containerClassName}`}>
			<label htmlFor={label} className="text-[1.4rem] text-lightText font-medium mb-[10px]">
				{label}
			</label>
			<DatePicker dateFormat="dd MMMM" showYearDropdown showMonthDropdown dropdownMode="select" {...rest} />
			<ErrorMessage name={name} component="div" className="text-red-500 text-[1rem]" />
		</div>
	);
};

export default CustomDatePicker;
