import { useQuery } from "@tanstack/react-query";
import { format, parseISO } from "date-fns";
import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getOrders } from "src/api";
import { renderCurrency } from "src/assets/functions";
import { EditIcon, UserIcon } from "src/assets/svg";
import { CustomDataTable, Modal } from "src/components";
import { useProtectedRoutesContext } from "src/context/ProtectedRoutes";
import { OrderDetails } from "src/modals/orders";

const QuickDrops = () => {
	const { user } = useProtectedRoutesContext();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const page = searchParams.get("page") ?? 1;
	const per_page = searchParams.get("per_page") ?? 50;

	const [isOrderDetailsModalOpen, setIsOrderDetailsModalOpen] = useState(false);
	const [selectedOrder, setSelectedOrder] = useState(null);

	const columns = [
		{
			name: "Customer",
			selector: (row) => row?.customer?.full_name,
			width: "250px",
			compact: true,
		},
		{
			name: "Location",
			selector: (row) => row?.location?.locationName,
			width: "200px",
			compact: true,
		},
		{
			name: "Placed",
			selector: (row) => format(parseISO(row?.created_at), "dd MMM yyyy"),
			width: "150px",
			compact: true,
		},
		{
			name: "Ready By",
			selector: (row) => (row?.dateTimeOut ? format(new Date(row?.dateTimeOut), "dd MMM yyyy") : "N/A"),
			width: "120px",
			compact: true,
		},
		{
			name: "Order",
			cell: (row) => (
				<div className="text-[1.2rem] font-medium leading-relaxed w-[95%] py-[7px] capitalize">
					{row?.transactions?.reduce(
						(string, { item }) => string.concat(item?.brand, " ", item?.description || "", " ", item?.product?.name, ", "),
						""
					)}
				</div>
			),
			wrap: true,
			minWidth: "200px",
			compact: true,
		},
		{
			name: "Total",
			selector: (row) => renderCurrency(row?.bill),
			width: "130px",
			compact: true,
		},
		{
			name: "",
			cell: (row) => (
				<div className="flex gap-4">
					<EditIcon className="cursor-pointer stroke-primary text-primary" onClick={() => navigate(`/orders?tab=0&quickDrop=${row?.id}`)} />
					<UserIcon
						className="cursor-pointer stroke-primary text-primary"
						onClick={() => {
							setSelectedOrder(row);
							setIsOrderDetailsModalOpen(true);
						}}
					/>
				</div>
			),
			width: "fit-content",
			compact: true,
		},
	];

	const location = user?.role === "admin" ? "" : user?.location_id;

	const { data } = useQuery({
		queryKey: ["orders", "pre-order", location, page, per_page],
		queryFn: () => getOrders({ status: "pre-order", location, page, per_page }),
		suspense: true,
	});

	return (
		<>
			<div>
				<div className="flex items-center justify-between my-[2rem]">
					<h1 className="text-black text-[2rem] font-bold">Quick Drops</h1>
				</div>
				<CustomDataTable columns={columns} data={data?.data?.data} paginationTotalRows={data?.data?.total} />
			</div>
			<Modal isOpen={isOrderDetailsModalOpen} setIsOpen={setIsOrderDetailsModalOpen} title="Order Details" size="large">
				<OrderDetails page="Quick Drops" selectedOrder={selectedOrder} />
			</Modal>
		</>
	);
};

export default QuickDrops;
