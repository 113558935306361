import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom";
import "./App.css";
import MainLayout from "./pages/Dashboard/layout";
import AuthLayout from "./pages/Authentication/layout/AuthLayout";
import ErrorPage from "./pages/ErrorPage";
import {
	Customers,
	Dashboard,
	Orders,
	TrackItem,
	Reports,
	Users,
	Settings,
	ProductGroups,
	Print,
	Metrics,
	Receipt,
	PrintAll,
	Sheets,
	PrintSheet,
} from "./pages/Dashboard";
import Login from "./pages/Authentication/Login";
import { useProtectedRoutesContext } from "./context/ProtectedRoutes";
import { Suspense } from "react";
import { Loader } from "./components";
import EditOrder from "./pages/Dashboard/Orders/EditOrder";
import Logout from "./pages/Logout";

function App() {
	const { user } = useProtectedRoutesContext();

	const router = createBrowserRouter([
		{
			path: "/",
			element: <AuthLayout />,
			errorElement: <ErrorPage />,
			children: [
				{
					path: "",
					element: <Login />,
				},
			],
		},
		{
			path: "/",
			element: <MainLayout />,
			errorElement: <ErrorPage />,
			children: [
				{
					path: "logout",
					element: <Logout />,
				},
				{
					path: "dashboard",
					element: <Dashboard />,
				},
				{
					path: "orders",
					element: <Orders />,
				},
				{
					path: "orders/:id",
					element: <EditOrder />,
				},
				{
					path: "print/:tagId/:orderId",
					element: <Print />,
				},
				{
					path: "print/small/:tagId/:orderId",
					element: <Print small />,
				},
				{
					path: "print-all/:orderId",
					element: <PrintAll />,
				},
				{
					path: "print-all/small/:orderId",
					element: <PrintAll small />,
				},
				{
					path: "receipt/:orderId",
					element: <Receipt />,
				},
				{
					path: "customers",
					element: <Customers />,
				},
				{
					path: "track",
					element: <TrackItem />,
				},
				{
					path: "sheets",
					element: <Sheets />,
				},
				{
					path: "sheets/:id",
					element: <PrintSheet />,
				},
				...(user?.role === "admin"
					? [
							{
								path: "reports",
								element: <Reports />,
							},
							{
								path: "users",
								element: <Users />,
							},
							{
								path: "metrics",
								element: <Metrics />,
							},
							{
								path: "settings",
								element: <Settings />,
							},
							{
								path: "settings/product-group/:id",
								element: <ProductGroups />,
							},
					  ]
					: []),
			],
		},
		{
			path: "*",
			element: <Navigate to="/dashboard" />,
		},
	]);

	return (
		<Suspense fallback={<Loader spin />}>
			<RouterProvider router={router} />
		</Suspense>
	);
}

export default App;
