import React from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "./tabs.css";
import { CustomerOrders, CustomerPaidOrders, CustomerUnpaidOrders, Invoice } from "src/components/customers";

const CustomerDetails = ({ customerdetails }) => {
	return (
		<div>
			<h2 className="text-darkText text-[2rem] font-semibold mt-[4rem] mb-[2rem] capitalize">{customerdetails?.full_name}</h2>
			<Tabs className="modal-tabs">
				<TabList className="modal-tabs__tab-list">
					<Tab className="modal-tabs__tab" selectedClassName="modal-tabs__tab--selected">
						Orders
					</Tab>
					<Tab className="modal-tabs__tab" selectedClassName="modal-tabs__tab--selected">
						Paid
					</Tab>
					<Tab className="modal-tabs__tab" selectedClassName="modal-tabs__tab--selected">
						Unpaid
					</Tab>
					<Tab className="modal-tabs__tab" selectedClassName="modal-tabs__tab--selected">
						Invoice
					</Tab>
				</TabList>

				<TabPanel className="modal-tabs__tab-panel" selectedClassName="modal-tabs__tab-panel--selected">
					<CustomerOrders customerdetails={customerdetails} />
				</TabPanel>
				<TabPanel className="modal-tabs__tab-panel" selectedClassName="modal-tabs__tab-panel--selected">
					<CustomerPaidOrders customerdetails={customerdetails} />
				</TabPanel>
				<TabPanel className="modal-tabs__tab-panel" selectedClassName="modal-tabs__tab-panel--selected">
					<CustomerUnpaidOrders customerdetails={customerdetails} />
				</TabPanel>
				<TabPanel className="modal-tabs__tab-panel" selectedClassName="modal-tabs__tab-panel--selected">
					<Invoice customerdetails={customerdetails} />
				</TabPanel>
			</Tabs>
		</div>
	);
};

export default CustomerDetails;
