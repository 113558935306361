import React, { useState } from "react";
import OrderItemsSelector from "./OrderItemsSelector";
import OrderItemDetails from "./OrderItemDetails";

const OrderItems = ({ list, setIsModalOpen }) => {
	const [step, setStep] = useState(1);
	const [initialValues, setInitialValues] = useState([]);

	return (
		<div className="flex flex-col">
			{list.length > 0 ? (
				<>
					<p className="text-[1.2rem] text-primary font-semibold ml-auto my-[1rem]">
						Step {step} of {1 + initialValues.length}
					</p>

					{step === 1 ? (
						<OrderItemsSelector list={list} setStep={setStep} setIsModalOpen={setIsModalOpen} setInitialValues={setInitialValues} />
					) : (
						<OrderItemDetails setIsModalOpen={setIsModalOpen} setStep={setStep} initialValues={initialValues} />
					)}
				</>
			) : (
				<p className="text-[1.4rem] text-primary font-semibold text-center my-[1rem]">No item found</p>
			)}
		</div>
	);
};

export default OrderItems;
