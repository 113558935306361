import { useRef } from "react";
import BarChart from "src/BarChart";
import { CustomDataTable } from "src/components";
import "chart.js/auto";

import { getCleaningMetrics } from "src/api";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { renderCurrency } from "src/assets/functions";

function replaceNullWithDash(data) {
	if (data === null) {
		data = "_";
	}
	return data;
}

const splitDate = (data) => {
	if (data && data.includes("T")) {
		const date = data.split("T")[0];
		return date;
	}
	return data;
};

const Cleaning = () => {
	const ref = useRef();
	const [searchParams] = useSearchParams();

	const startDate = searchParams.get("startDate") || "2023-08-07";
	const endDate = searchParams.get("endDate") || "2023-08-14";

	const { data } = useQuery({
		queryKey: ["cleaningMetrics", startDate, endDate],
		queryFn: () => getCleaningMetrics({ startDate, endDate }),
		suspense: true,
	});

	const cleaningMetricsItems = data?.data.items;
	const cleaningMetricshour = data?.data?.hour;
	const cleaningMetricsDays = data?.data?.days;

	const itemsQuantity = cleaningMetricsItems.map((data) => data.Quantity);
	const Quantity = itemsQuantity.reduce((accumulator, currentValue) => {
		return accumulator + currentValue;
	}, 0);

	const itemsPieces = cleaningMetricsItems.map((data) => data.pieces);
	const pieces = itemsPieces.reduce((accumulator, currentValue) => {
		return accumulator + currentValue;
	}, 0);

	const itemsSales = cleaningMetricsItems.map((data) => data.Sales);
	const sales = itemsSales.filter((e) => e !== null);
	const totalSales = sales.reduce((accumulator, currentValue) => {
		return accumulator + parseFloat(currentValue);
	}, 0);

	const columns = [
		{
			name: "Product",
			selector: (row) => replaceNullWithDash(row?.product_name),
			width: "200px",
		},
		{
			name: "Section",
			selector: (row) => replaceNullWithDash(row?.group_name),
			width: "150px",
		},
		{
			name: "Date",
			selector: (row) => splitDate(row?.created_at),
			width: "150px",
		},
		{
			name: "Staff Name",
			selector: (row) => replaceNullWithDash(row?.staff),
			width: "150px",
		},
		{
			name: "Quantity",
			selector: (row) => replaceNullWithDash(row?.Quantity),
			width: "150px",
		},
		{
			name: "Pieces",
			selector: (row) => replaceNullWithDash(row?.pieces),
			width: "150px",
		},
		{
			name: "Sales",
			selector: (row) => row?.Sales,
			width: "150px",
		},
	];

	const footer = {
		Quantity,
		pieces,
		Sales: renderCurrency(totalSales),
		product_name: "Total",
	};

	return (
		<>
			<div className="px-[2.5rem] mt-[80px]">
				<div className="flex justify-between">
					<div style={{ width: 600 }}>
						<h1>Popular Hours</h1>
						<BarChart
							userData={{
								labels: cleaningMetricshour.map((data) => data.hour_of_day),
								datasets: [
									{
										label: "Popular Hours",
										data: cleaningMetricshour.map((data) => data.price),
										backgroundColor: ["#0000A7"],
										borderColor: "#0000A7",
										borderWidth: 1.9,
										barThickness: 40,
									},
								],
							}}
							ref={ref}
						/>
					</div>
					<div style={{ width: 600 }}>
						<h1>Popular Days</h1>
						<BarChart
							userData={{
								labels: cleaningMetricsDays.map((data) => data.day_of_week),
								datasets: [
									{
										label: "Popular Days",
										data: cleaningMetricsDays.map((data) => data.price),
										backgroundColor: ["#0000A7"],
										borderColor: "#0000A7",
										borderWidth: 1.9,
										barThickness: 40,
									},
								],
							}}
							ref={ref}
						/>
					</div>
				</div>
				<div className="mt-[100px]">
					<CustomDataTable columns={columns} data={cleaningMetricsItems} footer={footer} />
				</div>
			</div>
		</>
	);
};
export default Cleaning;
