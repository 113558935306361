import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ProSidebarProvider } from "react-pro-sidebar";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import ProtectedRoutesProvider from "./context/ProtectedRoutes";

const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false, // default: true
			refetchInterval: false,
		},
	},
});

root.render(
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<ProtectedRoutesProvider>
				<ProSidebarProvider>
					<App />
				</ProSidebarProvider>
			</ProtectedRoutesProvider>
			<ReactQueryDevtools />
		</QueryClientProvider>
	</React.StrictMode>
);
