import { createContext, useContext, useEffect, useState } from "react";
import { getFromStorage, setToStorage } from "src/assets/functions";

const ProtectedRoutesContext = createContext({
	user: true,
	setUser: () => {},
	setToken: () => {},
	token: true,
});

export const useProtectedRoutesContext = () => useContext(ProtectedRoutesContext);

const ProtectedRoutesProvider = ({ children }) => {
	const [user, setUser] = useState(getFromStorage("user"));
	const [token, setToken] = useState(getFromStorage("token"));

	useEffect(() => {
		setToStorage("user", user);
	}, [user]);
	useEffect(() => {
		setToStorage("token", token);
	}, [token]);

	return <ProtectedRoutesContext.Provider value={{ user, setUser, token, setToken }}>{children}</ProtectedRoutesContext.Provider>;
};

export default ProtectedRoutesProvider;
