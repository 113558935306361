import React from "react";
import { Formik } from "formik";
import { CustomTextInput } from "src/components";
import * as Yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { createRole, editRole } from "src/api";
import { renderErrorMessage, renderSuccessMessage } from "src/assets/functions";

const CreateRole = ({ role, refetch, setIsRoleModalOpen }) => {
	const initialValues = role ? { id: role?.id, name: role?.name } : { name: "" };

	const validationSchema = Yup.object().shape({
		name: Yup.string().required("Role name is required"),
	});

	const { mutate, isLoading } = useMutation(role ? editRole : createRole, {
		onSuccess: (res) => {
			renderSuccessMessage(res.message);
			refetch();
			setIsRoleModalOpen(false);
		},
		onError: (error) => {
			renderErrorMessage(error.message);
		},
	});

	return (
		<>
			<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={(values) => mutate(values)}>
				{({ values, handleChange, handleSubmit }) => (
					<form onSubmit={handleSubmit} className="flex flex-col">
						<div className="my-10 mb-20">
							<CustomTextInput label="Role Name" name="name" onChange={handleChange} value={values.name} />
						</div>
						<div className="flex gap-3 ml-auto">
							<button
								type="button"
								className="btn"
								onClick={() => {
									setIsRoleModalOpen(false);
								}}
							>
								Cancel
							</button>
							<button type="submit" disabled={isLoading} className="btn-primary">
								{role ? "Edit" : "Add"}
							</button>
						</div>
					</form>
				)}
			</Formik>
		</>
	);
};

export default CreateRole;
