import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "src/pages/Dashboard/Orders/tabs.css";
import NewOrder from "./NewOrder";
import OrderContextProvider from "./context";
import Processing from "./Processing";
import Ready from "./Ready";
import Completed from "./Completed";
import QuickDrops from "./QuickDrops";
import { IconInput } from "src/components";
import { SearchIcon } from "src/assets/svg";
import { useQuery } from "@tanstack/react-query";
import { getOrderById } from "src/api";
import { orderTabs } from "src/assets/constants";
import { useProtectedRoutesContext } from "src/context/ProtectedRoutes";
import Delivered from "./Delivered";
import Deleted from "./Deleted";

const Orders = () => {
	const { user } = useProtectedRoutesContext();
	const [searchParams, setSearchParams] = useSearchParams();
	const [searchdata, setSearchdata] = useState("");
	const [querySearch, setQuerySearch] = useState("");

	const handleSend = () => setQuerySearch(user?.role === "admin" ? searchdata : `${user?.location?.store_code}-${searchdata}`);

	const { data } = useQuery({
		queryKey: ["order", querySearch],
		queryFn: () => getOrderById(querySearch),
		enabled: !!querySearch,
		suspense: true,
		onSuccess: ({ data }) => {
			const info = data?.at(0);
			setSearchParams((prevParams) => ({ ...prevParams, tab: orderTabs[info?.status ?? "default"] }));
		},
	});

	const tempArray = data?.data;
	const tabNumber = parseInt(searchParams.get("tab")) || 0;

	return (
		<Tabs
			defaultIndex={tabNumber}
			selectedIndex={tabNumber}
			onSelect={(index) => {
				setSearchParams({ tab: index });
				setQuerySearch("");
				setSearchdata("");
			}}
		>
			<div className="flex items-center justify-between bg-white max-[700px]:flex-col-reverse">
				<TabList>
					<Tab>New Order</Tab>
					<Tab>Quick Drops</Tab>
					<Tab>Processing</Tab>
					<Tab>Ready</Tab>
					<Tab>Completed</Tab>
					<Tab>Delivered</Tab>
					<Tab>Deleted</Tab>
				</TabList>
				<div className="flex gap-3 max-[700px]:w-full">
					<IconInput
						icon={<div className="text-[1.25rem]">{user?.role === "admin" ? <SearchIcon /> : `${user?.location?.store_code}-`}</div>}
						className="w-[200px]"
						containerClassName="max-[700px]:ml-auto"
						placeholder="Search"
						value={searchdata}
						onChange={(event) => setSearchdata(event.target.value)}
						onKeyDown={(event) => {
							if (event.key === "Enter") {
								event.preventDefault();
								handleSend(searchdata);
							}
						}}
					/>
					<button className="mr-10 small-btn-primary" onClick={() => handleSend(searchdata)}>
						Search
					</button>
				</div>
			</div>

			<TabPanel>
				<OrderContextProvider>
					<NewOrder />
				</OrderContextProvider>
			</TabPanel>
			<TabPanel>
				<QuickDrops />
			</TabPanel>
			<TabPanel>
				<Processing tempArray={tempArray} />
			</TabPanel>
			<TabPanel>
				<Ready tempArray={tempArray} />
			</TabPanel>
			<TabPanel>
				<Completed tempArray={tempArray} />
			</TabPanel>
			<TabPanel>
				<Delivered tempArray={tempArray} />
			</TabPanel>
			<TabPanel>
				<Deleted tempArray={tempArray} />
			</TabPanel>
		</Tabs>
	);
};

export default Orders;
