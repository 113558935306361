import { Formik } from "formik";
import React from "react";
import { editPriceAtIndex } from "src/assets/functions";
import { CustomTextInput } from "src/components";
import { useOrderContext } from "src/pages/Dashboard/Orders/context";
import * as Yup from "yup";

const EditPrice = ({ setIsOpen, index }) => {
	const { setOrderItems, orderItems } = useOrderContext();

	const initialValues = {
		amount: 0,
	};
	const validationSchema = Yup.object().shape({
		amount: Yup.number().required("Amount is required").min(1, "Minimum amount is N 0"),
	});

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values) => {
				setOrderItems(editPriceAtIndex(orderItems, index, values.amount));
				setIsOpen(false);
			}}
		>
			{({ values, handleChange, handleSubmit }) => (
				<form onSubmit={handleSubmit}>
					<CustomTextInput
						label="Amount"
						name="amount"
						placeholder="Enter Amount"
						type="number"
						min={0}
						value={values.amount}
						onChange={handleChange}
					/>
					<button className="w-full mt-6 btn-primary">Change Price</button>
				</form>
			)}
		</Formik>
	);
};

export default EditPrice;
