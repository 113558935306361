import React from "react";
import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { getMetricsPeriod } from "src/api";
import BarChart from "../BarChart";
import { BarOptions } from "src/assets/constants";

const TopSections = ({ metricPeriod }) => {
	const [searchParams] = useSearchParams();

	const startDate = searchParams.get("startDate") || "2023-08-07";
	const endDate = searchParams.get("endDate") || "2023-08-14";
	const period = metricPeriod;

	const { data } = useQuery({
		queryKey: ["metricsPeriod", startDate, endDate, period],
		queryFn: () => getMetricsPeriod({ startDate, endDate, period }),
		suspense: true,
	});

	const chartData = {
		labels: data?.data?.topSections[0].map((item) => item.name),
		datasets: [
			{
				data: data?.data?.topSections[0].map((item) => item.total_paid_amount),
				backgroundColor: "#0000A7",
				borderWidth: 0,
				borderSkipped: false,
				borderRadius: 10,
				barPercentage: 0.7,
				categoryPercentage: 0.8,
			},
		],
	};

	return (
		<div className="py-10 overflow-auto bg-white border graph-box px-14 rounded-xl">
			<div className="flex items-center gap-5 title">
				<h2 className="text-3xl font-semibold">Top Sections</h2>
			</div>
			<div className="overflow-auto graph">
				<BarChart data={chartData} options={BarOptions} />
			</div>
		</div>
	);
};

export default TopSections;
