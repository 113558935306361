import React, { useState } from "react";
import { Modal } from "src/components";
import OrderItems from "./OrderItems";
import cloth from "src/assets/images/cloth.jpg";

const TabOption = ({ option }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	return (
		<>
			<button
				className="px-[1rem] py-[1.5rem] leading-[12px] rounded-lg border border-[#E7E5EA] flex flex-col items-center justify-center"
				onClick={() => setIsModalOpen(true)}
			>
				<p className={`text-center text-darkText text-[1.4rem] font-semibold mb-[1rem] capitalize`}>{option.name}</p>
				<img src={option.avatar || cloth} alt="item" className="w-[85px] aspect-square rounded-full" />
			</button>
			<Modal isOpen={isModalOpen} setIsOpen={setIsModalOpen} title={option.name}>
				<OrderItems list={option.options} setIsModalOpen={setIsModalOpen} />
			</Modal>
		</>
	);
};

export default TabOption;
