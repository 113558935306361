import { ErrorMessage, Formik, useFormikContext } from "formik";
import { CustomTextInput } from "src/components";
import * as Yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { isValidFileType, renderErrorMessage, renderSuccessMessage } from "src/assets/functions";
import { createPattern, editPattern } from "src/api";
import { MAX_FILE_SIZE } from "src/assets/constants";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import avatar from "src/assets/avatar.png";

const AddPattern = ({ selectedpatterndetails, refetch, setOpenModal }) => {
	const { mutate, isLoading } = useMutation(selectedpatterndetails ? editPattern : createPattern, {
		onSuccess: (data) => {
			renderSuccessMessage(data.message).then(() => {
				refetch();
				setOpenModal(false);
			});
		},
		onError: (error) => {
			renderErrorMessage(error.message);
		},
	});

	const initialValues = selectedpatterndetails
		? {
				id: selectedpatterndetails.id,
				full_version: selectedpatterndetails.full_version,
				image: selectedpatterndetails?.image ?? null,
		  }
		: { full_version: "", image: "" };

	const validationSchema = Yup.object().shape({
		full_version: Yup.string().required("Staff is required"),
		image: Yup.mixed()
			.required("Required")
			.test("is-valid-type", "Not a valid image type", (value) => {
				if (typeof value === "string" || value instanceof String) {
					return true;
				} else {
					return isValidFileType(value && value.name.toLowerCase(), "courseImg");
				}
			})
			.test("is-valid-size", "Max allowed size is 500KB", (value) => {
				if (typeof value === "string" || value instanceof String) {
					return true;
				} else {
					return value && value.size <= MAX_FILE_SIZE;
				}
			}),
	});

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values) => {
				if (typeof values.image === "string" || values.image instanceof String) {
					delete values["image"];
				}

				let formData = new FormData();
				Object.entries(values).forEach(([key, value]) => {
					if (Array.isArray(value)) {
						value.forEach((item) => {
							formData.append(key, item);
						});
					} else {
						formData.append(key, value);
					}
				});
				mutate(values);
			}}
		>
			{({ values, handleChange, handleSubmit }) => (
				<form onSubmit={handleSubmit} className="flex flex-col">
					<AvatarDropzone />
					<ErrorMessage name="image" component="div" className="text-red-500 text-[1rem] mb-[20px]" />
					<div className="flex flex-col gap-10 my-10 mb-20">
						<CustomTextInput
							label="Pattern Type"
							name="full_version"
							onChange={handleChange}
							value={values.full_version}
							placeholder="Pattern Type"
						/>
						<ErrorMessage name="full_version" component="div" className="text-red-500 text-[1rem]" />
					</div>
					<div className="flex gap-3 ml-auto">
						<button
							className="btn"
							type="button"
							onClick={() => {
								setOpenModal(false);
							}}
						>
							Cancel
						</button>
						<button className="btn-primary" disabled={isLoading}>
							{selectedpatterndetails ? "Edit" : "Create"}
						</button>
					</div>
				</form>
			)}
		</Formik>
	);
};

const AvatarDropzone = () => {
	const { setFieldValue, values } = useFormikContext();

	const onDrop = useCallback(
		(acceptedFiles) => {
			setFieldValue(
				"image",
				acceptedFiles.map((file) =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
					})
				)[0]
			);
		},
		[setFieldValue]
	);

	const { getRootProps, getInputProps } = useDropzone({
		multiple: false,
		onDrop,
	});

	return (
		<div className="border border-[#F2F4F7] p-[15px] rounded-md flex items-center gap-3 cursor-pointer mb-[15px]" {...getRootProps()}>
			<input {...getInputProps()} aria-label="avatar-upload" />
			{!values.image ? (
				<img src={avatar} alt="user-avatar" className="rounded-full w-[100px] aspect-square" />
			) : typeof values.image === "string" || values.image instanceof String ? (
				<img src={`${values?.image}`} alt="user-avatar" className="rounded-full w-[100px] aspect-square" />
			) : (
				<img src={values?.image?.preview} alt="user-avatar" className="rounded-full w-[100px] aspect-square" />
			)}
			<div>
				<h4 className="text-[#0c78f1] text-[1.6rem] mb-2 font-medium">Upload Pattern Photo</h4>
				<p className="text-[#96A1AE] text-[1.2rem]">max 800x400 px</p>
			</div>
		</div>
	);
};

export default AddPattern;
