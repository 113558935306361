import { useMutation } from "@tanstack/react-query";
import { Formik } from "formik";
import React from "react";
import { scanOrder, setAsCompleted } from "src/api";
import { renderSuccessMessage } from "src/assets/functions";
import CustomTextInput from "../CustomTextInput";
import { useProtectedRoutesContext } from "src/context/ProtectedRoutes";

const MarkAsComplete = ({ selectedOrder, setIsOpen, refetch }) => {
	const { user } = useProtectedRoutesContext();
	const initialValues = {
		id: selectedOrder?.id,
		amount: 0,
		isWallet: false,
	};

	const { mutateAsync: payMutate, isLoading } = useMutation(setAsCompleted, {
		onSuccess: ({ message }) =>
			renderSuccessMessage(message).then(() => {
				refetch();
				setIsOpen();
			}),
	});
	const { mutateAsync: scanMutate, isLoading: isScanLoading } = useMutation(scanOrder);

	const fullyPaid = parseFloat(selectedOrder?.bill) === parseFloat(selectedOrder?.paidAmount);

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={async (values) => {
				try {
					await scanMutate({
						departmentId: user?.department?.id,
						orderId: selectedOrder?.id,
						stage: "scan-out",
					});
					await payMutate(values);
				} catch (error) {
					console.log(error);
				}
			}}
		>
			{({ values, handleChange, handleSubmit, setFieldValue }) => (
				<form onSubmit={handleSubmit} className="flex flex-col gap-6">
					<div className="flex items-center gap-2 bg-lightGray rounded-md px-[12px] py-[10px] mb-[10px] flex-1 max-h-[80px]">
						<input
							type="checkbox"
							checked={values.isWallet}
							id="Is Wallet Payment"
							name="isWallet"
							disabled={fullyPaid}
							onChange={(event) => setFieldValue("isWallet", event.target.checked)}
						/>
						<label htmlFor="Is Wallet Payment" className="text-darkText text-[1.4rem] font-medium">
							Is Wallet Payment
						</label>
					</div>
					<CustomTextInput
						label="Amount"
						placeholder="Enter Amount"
						type="number"
						min={0}
						name="amount"
						value={values.amount}
						onChange={handleChange}
						disabled={fullyPaid}
					/>
					<button className="mt-4 font-semibold btn-primary" disabled={isScanLoading || isLoading}>
						Confirm
					</button>
				</form>
			)}
		</Formik>
	);
};

export default MarkAsComplete;
