import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { deleteProduct, deleteProductOption, getProductGroupById } from "src/api";
import { renderConfirmDialogue, renderSuccessMessage } from "src/assets/functions";
import { AddIcon, DeleteIcon, EditIcon } from "src/assets/svg";
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from "react-accessible-accordion";
import "./accordion.css";
import { Modal } from "src/components";
import AddNewProduct from "src/modals/settings/AddNewProduct";
import AddNewProductOption from "src/modals/settings/AddNewProductOption";

const ProductGroup = () => {
	let { id } = useParams();

	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	const [openTabModal, setOpenTabModal] = useState(false);
	const [openCreateOptionModal, setOpenCreateOptionModal] = useState(false);
	const [selectedProduct, setSelectedProduct] = useState(null);

	const { data, refetch } = useQuery({
		queryKey: ["product-groups", id],
		queryFn: () => getProductGroupById(id),
		suspense: true,
	});

	const { mutate: deleteProductMutate } = useMutation(deleteProduct, {
		onSuccess: ({ message }) => renderSuccessMessage(message).then(() => refetch()),
	});

	const productGroup = data?.data;

	return (
		<>
			<div className="bg-white border-b border-b-lightGray flex items-center justify-between py-[1.45rem]">
				<div className="inline-flex items-center gap-5">
					<div className="text-justify text-black font-semibold text-[1.9rem] capitalize">{productGroup?.group_name}</div>
				</div>
				<div>
					<button className="btn-primary" onClick={() => setOpenTabModal(true)}>
						Add New
					</button>
				</div>
			</div>
			<div>
				{!productGroup?.products?.length ? (
					<div className="flex items-center justify-center text-[2rem] py-[2rem]">No products yet</div>
				) : (
					<Accordion allowMultipleExpanded allowZeroExpanded>
						{productGroup?.products.map((product) => (
							<AccordionItem key={product.id}>
								<AccordionItemHeading>
									<AccordionItemButton>
										<p style={{ display: "inline-flex", flex: 1 }}>{product.name}</p>
										<div className="flex gap-5 px-10 item-center icons" style={{ display: "inline-flex" }}>
											<AddIcon
												className="cursor-pointer text-primary"
												onClick={() => {
													setOpenCreateOptionModal(true);
													setSelectedProduct(product);
												}}
											/>
											<EditIcon
												className="cursor-pointer text-primary"
												onClick={() => {
													setIsEditModalOpen(true);
													setSelectedProduct(product);
												}}
											/>
											<DeleteIcon
												className="cursor-pointer text-primary"
												onClick={() =>
													renderConfirmDialogue("Do you want to delete this product?").then((result) => {
														if (result.isConfirmed) deleteProductMutate(product.id);
													})
												}
											/>
										</div>
									</AccordionItemButton>
								</AccordionItemHeading>
								<AccordionItemPanel>
									{product?.options?.map((option, index) => (
										<ProductOption option={option} refetch={refetch} product={product} key={index} />
									))}
								</AccordionItemPanel>
							</AccordionItem>
						))}
					</Accordion>
				)}
			</div>
			<Modal isOpen={openTabModal} setIsOpen={setOpenTabModal} title="Add Product">
				<AddNewProduct setOpenModal={setOpenTabModal} refetch={refetch} />
			</Modal>
			<Modal isOpen={isEditModalOpen} setIsOpen={setIsEditModalOpen} title="Edit Product">
				<AddNewProduct setOpenModal={setIsEditModalOpen} refetch={refetch} selectedProduct={selectedProduct} />
			</Modal>
			<Modal isOpen={openCreateOptionModal} setIsOpen={setOpenCreateOptionModal} title="Add Product Option">
				<AddNewProductOption setOpenModal={setOpenCreateOptionModal} refetch={refetch} product={selectedProduct} />
			</Modal>
		</>
	);
};

const ProductOption = ({ option, refetch }) => {
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);

	const { mutate: deleteProductOptionMutate } = useMutation(deleteProductOption, {
		onSuccess: ({ message }) => renderSuccessMessage(message).then(() => refetch()),
	});

	return (
		<>
			<div className="border border-[#f0f0f0] py-[12px] px-[15px] flex justify-between items-center">
				<p className="capitalize">{option?.option_name}</p>
				<div className="flex gap-5 px-10 item-center icons" style={{ display: "inline-flex" }}>
					<EditIcon
						className="cursor-pointer text-primary"
						onClick={() => {
							setIsEditModalOpen(true);
						}}
					/>
					<DeleteIcon
						className="cursor-pointer text-primary"
						onClick={() =>
							renderConfirmDialogue("Do you want to delete this option?").then((result) => {
								if (result.isConfirmed) deleteProductOptionMutate(option.id);
							})
						}
					/>
				</div>
			</div>

			<Modal isOpen={isEditModalOpen} setIsOpen={setIsEditModalOpen} title="Edit Product Option">
				<AddNewProductOption setOpenModal={setIsEditModalOpen} refetch={refetch} selectedProductOption={option} />
			</Modal>
		</>
	);
};

export default ProductGroup;
