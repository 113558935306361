import LineGraph from "../LineGraph";

import { useSearchParams } from "react-router-dom";
import { getMetricsPeriod } from "src/api";
import { useQuery } from "@tanstack/react-query";
import { options, createDataset, prepareChartData } from "src/assets/constants";
import { ctrlMetricNumber, ctrlPercentageMetric } from "src/assets/functions";

const SalesGraph = ({ metricPeriod }) => {
	const [searchParams] = useSearchParams();

	const startDate = searchParams.get("startDate") || "2023-08-07";
	const endDate = searchParams.get("endDate") || "2023-08-14";
	const period = metricPeriod;

	const { data } = useQuery({
		queryKey: ["metricsPeriod", startDate, endDate, period],
		queryFn: () => getMetricsPeriod({ startDate, endDate, period }),
		suspense: true,
	});

	const salesMetricsData = data?.data?.sales;

	const salesPeriod1Data = prepareChartData(salesMetricsData["sales-period-1"].sales);
	const salesPeriod2Data = prepareChartData(salesMetricsData["sales-period-2"].sales);

	const dataset1 = createDataset(salesPeriod1Data, "Total", "#999");
	const dataset2 = createDataset(salesPeriod2Data, "Total", "#0000A7");

	const chartData = {
		datasets: [dataset2, dataset1],
	};

	const [metricPercentage, percentageColor, percentageBg] = ctrlPercentageMetric(salesMetricsData?.percentageDifference);

	const [salesPrice1, salesPrice2] = ctrlMetricNumber(
		salesMetricsData["sales-period-1"]["sales-period1-total"],
		salesMetricsData["sales-period-2"]["sales-period2-total"],
		"NGN",
		2
	);

	return (
		<div className="py-10 bg-white border graph-box px-14 rounded-xl">
			<div className="flex items-center gap-5 mb-12 title">
				<h2 className="text-3xl font-semibold">Sales</h2>
				<span
					style={{
						color: percentageColor,
						backgroundColor: percentageBg,
					}}
					className={`text-xl transition-all py-1 px-3 rounded-3xl !important`}
				>{`${metricPercentage}%`}</span>
			</div>
			<div className="flex flex-col justify-between md:flex-row prices mb-7">
				<div className="p1 text-[#0000A7]">{`${salesPrice2}`}</div>
				<div className="p2">{`${salesPrice1}`}</div>
			</div>
			<div className="graph w-[430]">
				<LineGraph data={chartData} options={options} />
			</div>
		</div>
	);
};

export default SalesGraph;
