
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { Suspense } from "react";
import { Loader } from "src/components";
import CustomerOrder from './CustomerOrder';
import NewCustomer from './NewCustomer';
import { useQuery } from '@tanstack/react-query';
import { getCustomerOrderMetrics } from 'src/api';
import CustomerWithNoRecentOrder from './CustomerWithNoRecentOrder';



const CustomerTab = () => {
    const { data } = useQuery({
        queryKey: ["getCustomerOrderMetrics"],
        queryFn: getCustomerOrderMetrics,
        suspense: true,
    });

    return (
        <>
            <Tabs
                className="modal-tabs"
            >
                <TabList className="bg-white border-b border-b-lightGray flex items-center justify-between py-[1.45rem] w-full px-[2.5rem]">
                    <div className='tabs'>
                        <Tab className="modal-tabs__tab" selectedClassName="modal-tabs__tab--selected">
                            Orders
                        </Tab>
                        <Tab className="modal-tabs__tab" selectedClassName="modal-tabs__tab--selected">
                            New Customers
                        </Tab>
                        <Tab className="modal-tabs__tab" selectedClassName="modal-tabs__tab--selected">
                            Customers with No Recent Orders
                        </Tab>
                        <Tab className="modal-tabs__tab" selectedClassName="modal-tabs__tab--selected">
                            Deactivated Customers
                        </Tab>
                    </div>
                </TabList>

                <Suspense fallback={<Loader spin />}>
                    <TabPanel className="modal-tabs__tab-panel" selectedClassName="modal-tabs__tab-panel--selected">
                        <CustomerOrder customerOrders={data} />
                    </TabPanel>
                    <TabPanel className="modal-tabs__tab-panel" selectedClassName="modal-tabs__tab-panel--selected px-[2.5rem]">
                        < NewCustomer customerOrders={data} />
                    </TabPanel>
                    <TabPanel className="modal-tabs__tab-panel" selectedClassName="modal-tabs__tab-panel--selected px-[2.5rem]">
                        <CustomerWithNoRecentOrder customerOrders={data} />
                    </TabPanel>
                    <TabPanel className="modal-tabs__tab-panel" selectedClassName="modal-tabs__tab-panel--selected px-[2.5rem]">
                        <h2> Deactivated Customers</h2>
                    </TabPanel>
                </Suspense>
            </Tabs>

        </>

    )
};
export default CustomerTab;