import { useMutation } from "@tanstack/react-query";
import { Formik } from "formik";
import React from "react";
import { fundCustomerWallet } from "src/api";
import { renderSuccessMessage } from "src/assets/functions";
import { CustomTextInput } from "src/components";
import * as Yup from "yup";

const FundWallet = ({ customerdetails, setIsOpen, refetch }) => {
	const initialValues = {
		id: customerdetails?.uuid,
		amount: 0,
	};
	const validationSchema = Yup.object().shape({
		amount: Yup.number().required("Please provide an amount to deposit into wallet").min(100, "Minimum amount depositable is N100"),
	});

	const { mutate, isLoading } = useMutation(fundCustomerWallet, {
		onSuccess: ({ message }) =>
			renderSuccessMessage(message).then(() => {
				setIsOpen();
				refetch();
			}),
	});
	return (
		<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={(values) => mutate(values)}>
			{({ values, handleChange, handleSubmit }) => (
				<form onSubmit={handleSubmit} className="flex flex-col gap-4">
					<CustomTextInput
						label="Amount"
						name="amount"
						onChange={handleChange}
						value={values.amount}
						placeholder="Enter Amount"
						type="number"
						min={0}
					/>
					<button type="submit" className="btn-primary" disabled={isLoading}>
						Fund Wallet
					</button>
				</form>
			)}
		</Formik>
	);
};

export default FundWallet;
