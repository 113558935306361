import customersIcon from "src/assets/images/totalcustomers.png";
import { CustomDataTable } from "src/components";
import { renderCurrency, renderNumber } from "src/assets/functions";

const CustomerOrder = ({ customerOrders }) => {
	const columns = [
		{
			name: "Customer Name",
			selector: (row) => row.full_name,
		},
		{
			name: "Orders",
			selector: (row) => row.total_order_amount,
		},
		{
			name: "Sales",
			selector: (row) => row.total_sales,
		},
		{
			name: "Revenue",
			selector: (row) => row.total_revenue,
		},
	];

	return (
		<div className="px-[2.5rem]">
			<div className="flex justify-between">
				<h2 className="my-[30px] text-black text-[2rem] font-bold">Customer Order</h2>
			</div>
			<div className="grid grid-cols-2 mb-8 border border-gray-300 divide-x divide-gray-300 rounded-lg">
				<div className="px-4 py-5">
					<div className="flex justify-between">
						<p className="text-[1.4rem] text-darkText font-medium">Total Customers</p>
						{<img src={customersIcon} alt="" />}
					</div>
					<p className="text-[2rem] leading-[3rem] font-bold">{renderNumber(customerOrders?.data?.totalCustomers[0]?.total_customers)}</p>
				</div>
				<div className="px-4 py-5">
					<div className="flex justify-between">
						<p className="text-[1.4rem] text-darkText font-medium">New Customers</p>
						{<img src={customersIcon} alt="" />}
					</div>
					<p className="text-[2rem] leading-[3rem] font-bold">{renderNumber(customerOrders?.data?.newCustomers[0]?.newCustomersCount)}</p>
				</div>
			</div>
			<div className="flex justify-between mt-[100px] px-[10rem]">
				<div>
					<h2 className="mb-[10px] font-[700] text-[18px]">Orders</h2>
					{customerOrders?.data?.topCustomers?.data.map((topCustomers) => {
						return (
							<div key={topCustomers?.id}>
								<p className="font-[300] text-[14px] text-[#47505B] mb-[10px]">
									{topCustomers?.full_name}
									<span>({topCustomers?.customer_total_orders})</span>
								</p>
							</div>
						);
					})}
				</div>
				<div>
					<h2 className="mb-[10px] font-[700] text-[18px]">Sales</h2>
					{customerOrders?.data?.topCustomers?.data.map((topCustomers) => {
						return (
							<div key={topCustomers?.id}>
								<p className="font-[300] text-[14px] text-[#47505B] mb-[10px]">
									{topCustomers?.total_order_amount ? renderCurrency(topCustomers?.total_order_amount) : "Not Available"}
								</p>
							</div>
						);
					})}
				</div>
				<div>
					<h2 className="mb-[10px] font-[700] text-[18px]">Revenue</h2>
					{customerOrders?.data?.topCustomers?.data.map((topCustomers) => {
						return (
							<div key={topCustomers?.id}>
								<p className="font-[300] text-[14px] text-[#47505B] mb-[10px]">
									{topCustomers?.total_order_amount ? renderCurrency(topCustomers?.total_revenue) : "Not Available"}
								</p>
							</div>
						);
					})}
				</div>
			</div>
			<div className="mt-[80px]">
				<CustomDataTable columns={columns} data={customerOrders.data.totalCustomersChart} />
			</div>
		</div>
	);
};
export default CustomerOrder;
