import React from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { ClockInOut } from "src/components/staff";

const StaffDetails = ({ selectedStaff }) => {
	return (
		<div>
			<h2 className="text-darkText text-[2rem] font-semibold mt-[4rem] mb-[2rem] capitalize">{selectedStaff?.fullName}</h2>
			<Tabs className="modal-tabs">
				<TabList className="modal-tabs__tab-list">
					<Tab className="modal-tabs__tab" selectedClassName="modal-tabs__tab--selected">
						Clock Ins and Outs
					</Tab>
				</TabList>

				<TabPanel className="modal-tabs__tab-panel" selectedClassName="modal-tabs__tab-panel--selected">
					<ClockInOut selectedStaff={selectedStaff} />
				</TabPanel>
			</Tabs>
		</div>
	);
};

export default StaffDetails;
