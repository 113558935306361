import React, { Suspense } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { styled } from "styled-components";
import SideBar from "./SideBar";
import { Loader } from "src/components";
import TopNav from "./TopNav";
import { useProtectedRoutesContext } from "src/context/ProtectedRoutes";

const Container = styled.div`
	display: flex;
	overflow: hidden;

	.main {
		max-height: 100vh;
		min-height: 100vh;
		flex: 1;
		width: calc(100% - 200px);
		background-color: #eeeeee75;
		overflow-y: scroll;

		& > div {
			padding-inline: 2.5rem;
		}
	}

	@media only screen and (max-width: 769px) {
		.main {
			min-height: 100vh;
			flex: 1;
			width: 100%;
		}
	}
`;

const MainLayout = () => {
	const { user, token } = useProtectedRoutesContext();

	if (!user || !token) return <Navigate to="/" />;

	return (
		<Container>
			<SideBar />
			<div className="main">
				<TopNav />
				<Suspense fallback={<Loader spin />}>
					<Outlet />
				</Suspense>
			</div>
		</Container>
	);
};

export default MainLayout;
