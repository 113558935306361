import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { generateBatchOutboundScanCodes, getOrder } from "src/api";
import QRCode from "qrcode";
import { format, parse } from "date-fns";

const PrintAll = ({ small }) => {
	const componentRef = React.useRef(null);

	const reactToPrintContent = React.useCallback(() => {
		return componentRef.current;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [componentRef.current]);

	const handlePrint = useReactToPrint({
		content: reactToPrintContent,
		documentTitle: "Print Tag",
		removeAfterPrint: true,
	});

	return (
		<div className="flex flex-col items-center justify-center min-h-[50vh] gap-3">
			<button className=" btn-primary" onClick={handlePrint}>
				Print All
			</button>
			<ComponentToPrint ref={componentRef} small={small} />
		</div>
	);
};

const ComponentToPrint = React.forwardRef((props, ref) => {
	const { small } = props;
	const { orderId } = useParams();
	const [searchParams] = useSearchParams();

	const { data } = useQuery({
		queryKey: ["print-all", orderId],
		queryFn: () => generateBatchOutboundScanCodes({ order_id: orderId, qr: searchParams.has("qrcode") }),
		suspense: true,
	});
	const { data: rawOrder } = useQuery({
		queryKey: ["order", orderId],
		queryFn: () => getOrder(orderId),
		suspense: true,
	});

	const order = rawOrder?.data?.at(0);

	return (
		<div ref={ref} className="flex flex-col items-center text-center w-fit max-w-[350px]">
			{data?.data?.map((item, index) => (
				<PrintInfo key={index} item={item} index={index + 1} order={order} small={small} />
			))}
		</div>
	);
});

const PrintInfo = ({ item, order, small, index }) => {
	const [searchParams] = useSearchParams();

	const [qr, setQr] = useState("");

	const tagId = item?.item?.tagId;

	QRCode.toDataURL(tagId, { type: "png" }).then((img) => {
		setQr(img);
	});

	if (searchParams.has("qrcode")) {
		return (
			<div>
				<p className="font-bold text-[2.2rem]">{order?.serial_number}</p>
				<p className="text-[2rem] font-bold pb-3 mb-8 border-b-4 border-black">{tagId}</p>
				<p className="font-semibold text-[1.2rem]">CLEANACE DRYCLEANERS</p>
				<img src={qr} alt="qrcode" className="w-[200px] aspect-square mx-auto" />
				<Details tagId={tagId} item={item} order={order} small={small} index={index} />
			</div>
		);
	}
	return (
		<div>
			<p className="font-bold text-[2.2rem]">{order?.serial_number}</p>
			<p className="text-[2rem] font-bold pb-3 mb-8 border-b-4 border-black ">{tagId}</p>
			<p className="font-semibold text-[1.2rem]">CLEANACE DRYCLEANERS</p>
			<div dangerouslySetInnerHTML={{ __html: item?.barcode }} className="w-[250px] mb-1 mx-auto"></div>
			<Details tagId={tagId} item={item} order={order} small={small} index={index} />
		</div>
	);
};

const Details = ({ item, tagId, order, small, index }) => {
	const extra_info = JSON.parse(item?.item?.extra_info);

	return (
		<>
			<p className="font-bold text-[3rem]">{order?.serial_number}</p>
			<p className="text-[2.8rem] font-bold">{tagId}</p>
			{!small && (
				<>
					<p className="text-[2.4rem] font-semibold">{item?.item?.product_option?.option_name}</p>
					<p className="text-[2.4rem] font-semibold capitalize">{item?.item?.brand}</p>
					<p className="text-[2.4rem] font-semibold capitalize">
						{extra_info?.color} | {extra_info?.style} | {extra_info?.material} | {extra_info?.pattern} | {extra_info?.stains}
					</p>
					<p className="text-[2.4rem] capitalize font-semibold mt-3">{extra_info?.damage}</p>
				</>
			)}
			<p className={`font-semibold text-[2rem] mt-3`}>
				{index} of {order?.itemsCount}
			</p>
			<p className={`font-semibold text-[2rem] ${!small && "mt-3"}`}>{order?.customer?.full_name}</p>
			<p className="font-bold text-[3rem]">{format(parse(order?.dateTimeOut, "yyyy-MM-dd HH:mm:ss", new Date()), "MMM dd")} </p>
			<p className="font-normal text-[1.6rem] mb-[35px]">{format(parse(order?.dateTimeOut, "yyyy-MM-dd HH:mm:ss", new Date()), "hh:mm a")}</p>
		</>
	);
};

export default PrintAll;
