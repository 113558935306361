import { useQuery } from "@tanstack/react-query";
import { format, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import { exportTracker, trackItem } from "src/api";
import { ExportIcon, SearchIcon } from "src/assets/svg";
import { useSearchParams } from "react-router-dom";
import { CustomDataTable, IconInput, Modal } from "src/components";
import { default as TrackItemModal } from "src/modals/TrackItem";

const TrackItem = () => {
	const [isTrackModalOpen, setIsTrackModalOpen] = useState(false);
	const [itemID, setItemID] = useState("");
	const [selectedItem, setSelectedItem] = useState(null);
	const [exportItem, setExportItem] = useState(null);
	const [run, setRun] = useState(false);
	const [searchParams] = useSearchParams();

	const columns = [
		{
			name: "Date",
			selector: (row) => format(parseISO(row?.created_at), "PPP"),
			width: "150px",
			compact: true,
		},
		{
			name: "Customer Name",
			selector: (row) => row?.full_name,
			grow: 1.5,
			compact: true,
		},
		{
			name: "Item",
			selector: (row) => {
				const extra_info = JSON.parse(row?.itemInfo);
				return `${extra_info?.color} ${row?.itemBrand} - ${row?.itemDescription ?? ""}`;
			},
			grow: 1.5,
			compact: true,
		},
		{
			name: "Status",
			selector: (row) => row?.status,
			style: { textTransform: "capitalize" },
			compact: true,
			width: "150px",
		},
		{
			name: "",
			cell: (row) => (
				<div className="flex gap-4">
					<ExportIcon className="cursor-pointer stroke-primary text-primary w-[30px] stroke-1" onClick={() => setExportItem(row.id)} />
				</div>
			),
			width: "50px",
			compact: true,
		},
	];

	useEffect(() => {
		if (searchParams.has("tagId")) {
			setItemID(searchParams.get("tagId"));
			setRun(true);
		}
	}, [searchParams]);

	const { data, isFetching } = useQuery({
		queryKey: ["track-item", itemID],
		queryFn: () => trackItem(itemID),
		enabled: run,
		onSuccess: () => {
			setRun(false);
		},
	});

	useQuery({
		queryKey: ["export-item", exportItem],
		queryFn: () => exportTracker(exportItem),
		enabled: !!exportItem,
		onSuccess: () => {
			setExportItem(null);
		},
	});

	return (
		<>
			<div>
				<div className="flex min-[500px]:items-center justify-between my-[2rem] max-[500px]:flex-col gap-6">
					<h1 className="text-black text-[2rem] font-bold">Track Item</h1>
					<div className="flex items-center gap-5 ml-auto border-gray-300">
						<IconInput
							icon={<SearchIcon />}
							className="w-[250px] "
							placeholder="Enter Item"
							value={itemID}
							onChange={(event) => setItemID(event.target.value)}
						/>
						<button className="btn-primary" onClick={() => !!itemID && setRun(true)} disabled={isFetching}>
							Enter
						</button>
					</div>
				</div>
				<CustomDataTable
					columns={columns}
					data={data?.data}
					onRowClicked={(row) => {
						setIsTrackModalOpen(true);
						setSelectedItem(row);
					}}
					pointerOnHover
					progressPending={isFetching}
				/>
			</div>
			<Modal isOpen={isTrackModalOpen} setIsOpen={setIsTrackModalOpen} title="Track Item">
				<TrackItemModal selectedItem={selectedItem} />
			</Modal>
		</>
	);
};

export default TrackItem;
