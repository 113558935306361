import { useMutation } from "@tanstack/react-query";
import { Formik } from "formik";
import React from "react";
import { deleteOrder } from "src/api";
import { renderErrorMessage, renderSuccessMessage } from "src/assets/functions";
import { CustomTextArea } from "src/components";
import * as Yup from "yup";

const DeleteOrder = ({ setIsOpen, refetch, selectedOrder }) => {
	const initialValues = {
		id: selectedOrder?.id,
		reason: "",
	};

	const validationSchema = Yup.object().shape({
		reason: Yup.string().required("Customer name is required"),
	});

	const { mutate, isLoading } = useMutation(deleteOrder, {
		onSuccess: (data) => {
			renderSuccessMessage(data.message).then(() => {
				refetch();
				setIsOpen(false);
			});
		},
		onError: (error) => {
			renderErrorMessage(error.message);
		},
	});
	return (
		<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={(values) => mutate(values)}>
			{({ values, handleSubmit, setFieldValue }) => (
				<form onSubmit={handleSubmit} className="flex flex-col">
					<div className="grid gap-4 mb-5">
						<CustomTextArea
							label="Reason"
							name="reason"
							value={values.reason}
							onChange={(event) => setFieldValue("reason", event.target.value)}
						/>
					</div>

					<div className="flex gap-3 ml-auto">
						<button className="btn" onClick={() => setIsOpen(false)}>
							Cancel
						</button>
						<button type="submit" className="btn-primary" disabled={isLoading}>
							Delete
						</button>
					</div>
				</form>
			)}
		</Formik>
	);
};

export default DeleteOrder;
