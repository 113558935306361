import React from "react";
import { ErrorMessage, Formik } from "formik";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { useMutation } from "@tanstack/react-query";

const validationSchema = Yup.object().shape({
	email: Yup.string().email("Please provide a valid email").required("Please provide a valid email"),
});

const initialValues = {
	email: "",
};

const ForgotPassword = ({ CancelModal }) => {
	const { mutate, isLoading } = useMutation("forgotPassword", {
		onSuccess: () => {
			Swal.fire({
				icon: "success",
				title: "Email Sent",
				text: "We have sent you an email that contains a link to reset your password",
				showConfirmButton: false,
				timer: false,
				confirmButtonText: "Back to Login",
				customClass: {
					container: "main-swal",
					htmlContainer: "custom-swal-htmlContainer",
					title: "custom-swal-title",
				},
			});
		},
		onError: (data) => {
			Swal.fire({
				icon: "error",
				title: data.message,
				text: "Please enter your correct email address",
				showConfirmButton: true,
				timer: false,
				confirmButtonText: "Try Again",
				customClass: {
					container: "main-swal",
					htmlContainer: "custom-swal-htmlContainer",
					title: "custom-swal-title",
					confirmButton: "custom-swal-confirm-button",
				},
			});
		},
	});

	return (
		<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={(values) => mutate(values)}>
			{({ values }) => {
				<form className="flex flex-col">
					<div className="form-group">
						<label className="lesson-label">Email Address</label>
						<input name="email" type="text" className="control-form" placeholder="hello@classcube.com" />
						<ErrorMessage name="email" component="div" className="text-red-500" />
					</div>
					<div className="ml-auto d-flex">
						<button type="btn" className="mr-4 forgot-submit1" disabled={isLoading} onClick={CancelModal}>
							Cancel
						</button>
						<button type="submit" className="forgot-submit" disabled={isLoading}>
							Submit
						</button>
					</div>
				</form>;
			}}
		</Formik>
	);
};
export default ForgotPassword;
