import { useMutation } from "@tanstack/react-query";
import { Formik, useFormikContext } from "formik";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useParams } from "react-router-dom";
import { createProduct, editProduct } from "src/api";
import { renderSuccessMessage } from "src/assets/functions";
import { CustomTextInput } from "src/components";
import * as Yup from "yup";
import avatar from "src/assets/avatar.png";

const AddNewProduct = ({ setOpenModal, refetch, selectedProduct }) => {
	let { id: product_group_id } = useParams();

	const initialValues = selectedProduct
		? {
				id: selectedProduct?.id,
				name: selectedProduct?.name,
				avatar: selectedProduct?.avatar,
				product_group_id: selectedProduct?.product_group_id,
		  }
		: { name: "", avatar: null, product_group_id: product_group_id };

	const validationSchema = Yup.object().shape({
		name: Yup.string().required("Product name is required"),
	});

	const { mutate, isLoading } = useMutation(selectedProduct ? editProduct : createProduct, {
		onSuccess: ({ message }) =>
			renderSuccessMessage(message).then(() => {
				refetch();
				setOpenModal(false);
			}),
	});

	return (
		<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={(values) => mutate(values)}>
			{({ values, handleChange, handleSubmit }) => (
				<form onSubmit={handleSubmit} className="flex flex-col">
					<div className="my-10">
						<CustomTextInput label="Product Name" name="name" onChange={handleChange} value={values.name} />
					</div>
					<AvatarDropzone />
					<div className="flex gap-3 ml-auto">
						<button className="btn" type="button" onClick={() => setOpenModal(false)}>
							Cancel
						</button>
						<button className="btn-primary" disabled={isLoading}>
							Save
						</button>
					</div>
				</form>
			)}
		</Formik>
	);
};

const AvatarDropzone = () => {
	const { setFieldValue, values } = useFormikContext();

	const onDrop = useCallback(
		(acceptedFiles) => {
			setFieldValue(
				"avatar",
				acceptedFiles.map((file) =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
					})
				)[0]
			);
		},
		[setFieldValue]
	);

	const { getRootProps, getInputProps } = useDropzone({
		multiple: false,
		onDrop,
	});

	return (
		<div className="border border-[#F2F4F7] p-[15px] rounded-md flex items-center gap-3 cursor-pointer mb-20" {...getRootProps()}>
			<input {...getInputProps()} aria-label="avatar-upload" />
			{!values.avatar ? (
				<img src={avatar} alt="user-avatar" className="rounded-full w-[100px] aspect-square" />
			) : typeof values.avatar === "string" || values.avatar instanceof String ? (
				<img src={`${values?.avatar}`} alt="user-avatar" className="rounded-full w-[100px] aspect-square" />
			) : (
				<img src={values?.avatar?.preview} alt="user-avatar" className="rounded-full w-[100px] aspect-square" />
			)}
			<div>
				<h4 className="text-[#0c78f1] text-[1.6rem] mb-2 font-medium">Upload Product Photo</h4>
				<p className="text-[#96A1AE] text-[1.2rem]">max 800x400 px</p>
			</div>
		</div>
	);
};

export default AddNewProduct;
