import product from "src/assets/images/product.png";

export const laundryOptions = [
	{
		name: "Agbada",
		image: product,
		options: [
			{ name: "Native", price: 1999 },
			{ name: "Corporate", price: 3950 },
		],
	},
	{
		name: "Blouse",
		image: product,
		options: [
			{ name: "White", price: 1999 },
			{ name: "Colored", price: 950 },
		],
	},
	{
		name: "Shirts",
		image: product,
		options: [
			{ name: "T-Shirt", price: 199 },
			{ name: "Round Neck", price: 250 },
			{ name: "Dress Shirt", price: 2500 },
			{ name: "Jean Shirt", price: 250 },
		],
	},
	{
		name: "Trousers",
		image: product,
		options: [
			{ name: "Cargo Pants", price: 199 },
			{ name: "Chinos", price: 250 },
			{ name: "Leggings", price: 2500 },
			{ name: "Jeans", price: 250 },
			{ name: "Joggers", price: 250 },
		],
	},
	{
		name: "Underwear",
		image: product,
		options: [
			{ name: "Thong", price: 199 },
			{ name: "Boxers", price: 250 },
			{ name: "Bra", price: 2500 },
			{ name: "Brief", price: 250 },
			{ name: "Joggers", price: 250 },
		],
	},
	{
		name: "Others",
		image: product,
		options: [
			{ name: "Big Towel", price: 199 },
			{ name: "Rag", price: 250 },
			{ name: "Small Towel", price: 2500 },
			{ name: "Bedsheet", price: 250 },
			{ name: "Pillow Case", price: 250 },
			{ name: "Dovet", price: 250 },
		],
	},
];

export const dryCleanOptions = [
	{
		name: "Suit",
		image: product,
		options: [
			{ name: "Suit Jacket", price: 199 },
			{ name: "Suit Vest", price: 250 },
			{ name: "Suit Trousers", price: 2500 },
		],
	},
];

export const carpetOptions = [
	{
		name: "Carpet",
		image: product,
		options: [
			{ name: "Suit Jacket", price: 199 },
			{ name: "Suit Vest", price: 250 },
			{ name: "Suit Trousers", price: 2500 },
		],
	},
];

export const productGroup = [
	{ name: "Laundry" },
	{ name: "Dry Cleaning" },
	{ name: "Carpets & Upholstery" },
	{ name: "Cobbling" },
	{ name: "Janitorial" },
];

export const departments = [
	{ name: "Inbound" },
	{ name: "Pressing" },
	{ name: "Dry Cleaning" },
	{ name: "Drivers" },
	{ name: "Quality Control" },
	{ name: "Packaging" },
	{ name: "Rejected" },
];

export const deliveryOptions = [{ name: "Basic - 500" }, { name: "Island - 1500" }, { name: "Regular - 1000" }, { name: "Enterprise - 2000" }];

export const defaultOptions = [];

export const newOrderTabs = [
	{ name: "Laundry", items: laundryOptions },
	{ name: "Dry Cleaning", items: dryCleanOptions },
	{ name: "Carpets & Upholstery", items: carpetOptions },
	{ name: "Alterations", items: defaultOptions },
	{ name: "Cobbling", items: defaultOptions },
	{ name: "Janitorial", items: defaultOptions },
];

export const settingsTabs = [
	{ name: "Product Groups", items: productGroup },
	{ name: "Departments", items: departments },
	{ name: "Locations" },
	{ name: "Routes" },
];

export const allReport = [
	{
		name: "Total Number and types of garments from each store that came into the factory",
		id: 1,
	},
	{
		name: "Number of garments processed/that left the factory per day",
		id: 4,
	},
	{ name: "Total Number of garments in each department", id: 2 },
	{
		name: "Number of garments processed/that left each department per day",
		id: 5,
	},
	{ name: "Number of garments sent for rewash per day", id: 6 },
	{ name: "Number of garments rejected", id: 8 },
	{ name: "Number of garments processed by individual", id: 9 },
];

export const UserData = [
	{
		id: 1,
		year: 2016,
		userGain: 80000,
		userLost: 823,
	},
	{
		id: 2,
		year: 2017,
		userGain: 45677,
		userLost: 345,
	},
	{
		id: 3,
		year: 2018,
		userGain: 78888,
		userLost: 555,
	},
	{
		id: 4,
		year: 2019,
		userGain: 90000,
		userLost: 4555,
	},
	{
		id: 5,
		year: 2020,
		userGain: 4300,
		userLost: 234,
	},
];

export const metricsPeriod = [
	{
		id: 1,
		periodText: "Previous Period",
		param: "previousPeriod",
	},
	{
		id: 2,
		periodText: "Previous Period Last Month",
		param: "lastMonth",
	},
	{
		id: 3,
		periodText: "Previous Period Last Quater",
		param: "lastQuarter",
	},
	{
		id: 4,
		periodText: "Previous Period Last Year",
		param: "lastYear",
	},
];

export const options = {
	scaleShowLabels: false,
	responsive: true,
	plugins: {
		legend: {
			display: false,
		},
	},
	scales: {
		y: {
			grid: {
				drawTicks: false,
				drawBorder: false,
				color: "#EAEAEA",
			},
			ticks: {
				display: false,
			},
			border: {
				display: false,
			},
			beginAtZero: true,
		},
		x: {
			display: false,
		},
	},
};

export const BarOptions = {
	scaleShowLabels: false,
	responsive: true,
	plugins: {
		legend: {
			display: false,
		},
	},
	indexAxis: "y",
	scales: {
		y: {
			beginAtZero: true,
			grid: {
				drawOnChartArea: false,
			},
		},
		x: {
			display: false,
		},
	},
};

export const createDataset = (data, label, borderColor) => ({
	label: label,
	data: data,
	borderColor: borderColor,
});

export const prepareChartData = (data) =>
	data.map((entry) => ({
		x: entry.order_date,
		y: entry.total,
	}));

export const orderTabs = {
	"pre-order": 1,
	processing: 2,
	ready: 3,
	completed: 4,
	delivered: 5,
	default: 0,
};

export const MAX_FILE_SIZE = 1024 * 500;

export const validFileExtensions = {
	courseImg: ["jpg", "png", "jpeg"],
};

export const fileTypes = {
	Image: [".jpg", ".jpeg", ".png"],
};

export const dropzoneAttachmentFormats = {
	"image/*": [".jpg", ".jpeg", ".png"],
};
