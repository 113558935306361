import LineChart from "src/LineChart";
import { CustomDataTable, FilterByDate, FilterDateTrigger } from "src/components";
import { getMetricsOrders } from "src/api";
import { useQuery } from "@tanstack/react-query";
import { renderCurrency } from "src/assets/functions";
import { format, parse, subDays } from "date-fns";
import { useSearchParams } from "react-router-dom";
import Popup from "reactjs-popup";

const OrderMetrics = () => {
	const [searchParams] = useSearchParams();

	const startDate = searchParams.get("startDate") || format(subDays(new Date(), 7), "yyyy-MM-dd");
	const endDate = searchParams.get("endDate") || format(new Date(), "yyyy-MM-dd");

	const parsedStartDate = parse(startDate, "yyyy-MM-dd", new Date());
	const parsedEndDate = parse(endDate, "yyyy-MM-dd", new Date());

	const formattedstartDate = format(parsedStartDate, "dd MMM yyyy");
	const formattedendDate = format(parsedEndDate, "dd MMM yyyy");

	const { data: orderMetric } = useQuery({
		queryKey: ["getMetricsOrders", startDate, endDate],
		queryFn: () =>
			getMetricsOrders({
				startDate,
				endDate,
			}),
		suspense: true,
	});

	const columns = [
		{
			name: "Date",
			selector: (row) => row?.Date && format(parse(row?.Date?.split(" ")[0], "yyyy-MM-dd", new Date()), "PPP"),
		},
		{
			name: "Orders",
			selector: (row) => row?.Orders,
		},
		{
			name: "Sales",
			selector: (row) => renderCurrency(row?.Sales),
		},
	];

	return (
		<div className="px-[2.5rem]">
			<div className="float-right mt-[25px]">
				<Popup
					trigger={<FilterDateTrigger name={`${formattedstartDate} - ${formattedendDate}`} />}
					arrow={false}
					closeOnDocumentClick
					className="date"
					position={["bottom left"]}
					contentStyle={{
						width: "fit-content",
						padding: 0,
					}}
				>
					<FilterByDate />
				</Popup>
			</div>
			<div style={{ height: 600 }}>
				<LineChart
					chartData={{
						labels: orderMetric?.data?.orderChart.map((data) => data.order_date),
						datasets: [
							{
								label: "Orders",
								data: orderMetric?.data?.orderChart.map((data) => data.total),
								backgroundColor: ["#0000A7"],
								borderColor: "#0000A7",
								borderWidth: 1.5,
							},
						],
					}}
				/>
			</div>
			<div className="mt-[100px]">
				<h2 className="mb-[20px] mt-[50px] text-[#49515C] font-[700]">Orders</h2>
				<CustomDataTable columns={columns} data={orderMetric?.data?.orders?.data} />
			</div>
		</div>
	);
};
export default OrderMetrics;
