import { ErrorMessage, Formik } from "formik";
import { CustomTextInput } from "src/components";
import * as Yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { renderErrorMessage, renderSuccessMessage } from "src/assets/functions";
import { createDamages, editDamages } from "src/api";

const AddDamage = ({ selecteddamagedetails, refetch, setOpenModal }) => {
	const { mutate, isLoading } = useMutation(selecteddamagedetails ? editDamages : createDamages, {
		onSuccess: (data) => {
			renderSuccessMessage(data.message).then(() => {
				refetch();
				setOpenModal(false);
			});
		},
		onError: (error) => {
			renderErrorMessage(error.message);
		},
	});

	const initialValues = selecteddamagedetails
		? {
				id: selecteddamagedetails.id,
				short_code: selecteddamagedetails.short_code,
				full_version: selecteddamagedetails.full_version,
		  }
		: { short_code: "", full_version: "" };

	const validationSchema = Yup.object().shape({
		short_code: Yup.string().required("Route name is required"),
		full_version: Yup.string().required("Staff is required"),
	});

	return (
		<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={(values) => mutate(values)}>
			{({ values, handleChange, handleSubmit }) => (
				<form onSubmit={handleSubmit} className="flex flex-col">
					<div className="flex flex-col gap-10 my-10 mb-20">
						<CustomTextInput
							label="Shortcode"
							name="short_code"
							onChange={handleChange}
							value={values.short_code}
							placeholder="Shortcode"
						/>
						<ErrorMessage name="short_code" component="div" className="text-red-500 text-[1rem]" />
						<CustomTextInput
							label="Damage Type"
							name="full_version"
							onChange={handleChange}
							value={values.full_version}
							placeholder="Damage Type"
						/>
						<ErrorMessage name="full_version" component="div" className="text-red-500 text-[1rem]" />
					</div>
					<div className="flex gap-3 ml-auto">
						<button
							className="btn"
							type="button"
							onClick={() => {
								setOpenModal(false);
							}}
						>
							Cancel
						</button>
						<button className="btn-primary" disabled={isLoading}>
							{selecteddamagedetails ? "Edit" : "Create"}
						</button>
					</div>
				</form>
			)}
		</Formik>
	);
};

export default AddDamage;
