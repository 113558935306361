import React from "react";
import DataTable from "react-data-table-component-footer";
import { useSearchParams } from "react-router-dom";
import Loader from "./Loader";

const CustomDataTable = (props) => {
	const [searchParams, setSearchParams] = useSearchParams();

	return (
		<div className="table-responsive mb-[70px]">
			<DataTable
				pagination
				progressComponent={<Loader spin />}
				paginationServer
				paginationDefaultPage={searchParams.has("page") ? parseInt(searchParams.get("page")) : 1}
				paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 40, 50, 100, 200]}
				paginationPerPage={searchParams.has("per_page") ? parseInt(searchParams.get("per_page")) : 50}
				onChangePage={(page) => {
					searchParams.set("page", page);
					setSearchParams(searchParams);
				}}
				onChangeRowsPerPage={(currentRowsPerPage) => {
					searchParams.set("per_page", currentRowsPerPage);
					setSearchParams(searchParams);
				}}
				customStyles={{
					table: {
						style: {
							borderTop: "1px solid #EAEBF0",
							borderLeft: "1px solid #EAEBF0",
							borderRight: "1px solid #EAEBF0",
							borderRadius: 8,
							paddingInline: "2rem",
						},
					},
					pagination: {
						style: {
							border: "1px solid #EAEBF0",
							borderBottomLeftRadius: "10px",
							borderBottomRightRadius: "10px",
						},
					},
					rows: {
						style: {
							fontSize: "1.4rem",
							fontWeight: 400,
							color: "#6E7079",
							"&:last-of-type": props.footer
								? {
										backgroundColor: "#0000A7",
										color: "#fff",
										borderBottomLeftRadius: "12px",
										borderBottomRightRadius: "12px",
								  }
								: {},
						},
					},
					headRow: {
						style: {
							fontSize: "1.4rem",
							fontWeight: 500,
							color: "#2C2D33",
						},
					},
					header: {
						style: {
							fontSize: "1.6rem",
							fontWeight: 500,
							color: "#45464E",
							borderTopLeftRadius: "10px",
							borderTopRightRadius: "10px",
							display: "none",
						},
					},
					subHeader: {
						style: {
							paddingInline: "2rem",
							paddingBlock: "1.5rem",
							borderTopLeftRadius: "10px",
							borderTopRightRadius: "10px",
						},
					},
				}}
				{...props}
			/>
		</div>
	);
};

export default CustomDataTable;
