import { Formik, useFormikContext } from "formik";
import React, { useState } from "react";
import { CustomSelectInput, CustomTextInput } from "src/components";
import CustomTextArea from "src/components/CustomTextArea";
import * as Yup from "yup";
import { useOrderContext } from "src/pages/Dashboard/Orders/context";
import { addSingleColorOrBrand, getAllDamages, getAllPattern, getItemSettings, getOrderOption } from "src/api";
import { useMutation, useQuery } from "@tanstack/react-query";
import CustomMultiSelect from "../CustomMultiSelect";
import { renderErrorMessage, renderSuccessMessage } from "src/assets/functions";
import "react-toggle/style.css";
import Toggle from "react-toggle";
import { components } from "react-select";
import toHex from "colornames";

const OrderItemDetails = ({ initialValues, setIsModalOpen, setStep }) => {
	const [index, setIndex] = useState(0);
	const { setOrderItems } = useOrderContext();

	const { data } = useQuery({
		queryKey: ["getOrderOption"],
		queryFn: getOrderOption,
		suspense: true,
	});

	const max_order_item = data?.data?.max_order_item;

	const incrementIndex = () => {
		setIndex((prevState) => prevState + 1);
		setStep((prevState) => prevState + 1);
	};
	const decrementIndex = () => {
		setIndex((prevState) => prevState - 1);
		setStep((prevState) => prevState - 1);
	};

	const validationSchema = Yup.object().shape({
		items: Yup.array().of(
			Yup.object().shape(
				{
					tag_id: Yup.string().when(["brand", "extra_info"], {
						is: (brand, extra_info) => !!extra_info.color || !!extra_info.material || !!extra_info.pattern || !!brand,
						then: (schema) => schema.notRequired(),
						otherwise: (schema) => schema.required("Please provide a tag for this item"),
					}),
					brand: Yup.string().when("tag_id", {
						is: (tag_id) => String(tag_id ?? "").length > 0,
						then: (schema) => schema.notRequired(),
						otherwise: (schema) => schema.required("Please provide an brand for this item"),
					}),

					extra_info: Yup.object().when("tag_id", {
						is: (tag_id) => String(tag_id ?? "").length > 0,
						then: (schema) => schema.notRequired(),
						otherwise: (schema) =>
							schema.shape({
								color: Yup.string().required("Please provide a color for this item"),
								material: Yup.string().required("Please provide the material for this item"),
								pattern: Yup.string().required("Please provide the pattern for this item"),
							}),
					}),
				},
				[
					["brand", "tag_id"],
					["extra_info", "tag_id"],
				]
			)
		),
	});

	return (
		<Formik
			initialValues={{ items: initialValues }}
			validationSchema={validationSchema}
			validateOnChange
			validateOnMount
			onSubmit={(values) => {
				const items = values.items.map((item) => ({ ...item, extra_info: JSON.stringify(item.extra_info) }));
				setOrderItems((prevState) => {
					const result = [...prevState, ...items];
					const number = result.reduce((count, currentItem) => count + parseInt(currentItem?.pieces ?? 1), 0);

					if (number > parseInt(max_order_item)) {
						renderErrorMessage("Items in an order cannot be more than 10", 5000);
						return prevState;
					} else {
						setIsModalOpen(false);
						return [...prevState, ...items];
					}
				});
			}}
		>
			{({ values, handleSubmit }) => (
				<form onSubmit={handleSubmit} className="flex flex-col">
					{values.items.map((item, itemKey) => (
						<Form
							key={itemKey}
							item={item}
							index={index}
							itemKey={itemKey}
							incrementIndex={incrementIndex}
							decrementIndex={decrementIndex}
							setIsModalOpen={setIsModalOpen}
						/>
					))}
				</form>
			)}
		</Formik>
	);
};

const Form = ({ item, index, itemKey, incrementIndex, decrementIndex, setIsModalOpen }) => {
	const { values, setFieldValue, initialValues, submitForm, errors } = useFormikContext();
	const [checked, setChecked] = useState(false);

	const { data, refetch } = useQuery({
		queryKey: ["item-Settings"],
		queryFn: () => getItemSettings(),
		suspense: true,
	});

	const { data: data1 } = useQuery({
		queryKey: ["getAllDamages"],
		queryFn: () => getAllDamages(),
		suspense: true,
	});

	const { data: getpattern } = useQuery({
		queryKey: ["getAllPattern"],
		queryFn: () => getAllPattern(),
		suspense: true,
	});

	const options = {
		stains: data.data[0]?.stains
			?.split(",")
			.filter((el) => el !== "")
			.map((data) => ({ label: data, value: data })),
		styles: data.data[0]?.styles
			?.split(",")
			.filter((el) => el !== "")
			.map((data) => ({ label: data, value: data })),
		patterns: data.data[0]?.patterns
			?.split(",")
			.filter((el) => el !== "")
			.map((data) => ({ label: data, value: data })),
		materials: data.data[0]?.materials
			?.split(",")
			.filter((el) => el !== "")
			.map((data) => ({ label: data, value: data })),
		brand: data.data[0]?.brand
			?.split(",")
			.filter((el) => el !== "")
			.map((data) => ({ label: data, value: data })),
		color: data.data[0]?.color
			?.split(",")
			.filter((el) => el !== "")
			.map((data) => ({ label: data, value: data })),
	};

	const options1 = {
		damage_info: data1?.data?.map((data) => ({ label: data.short_code, value: data.short_code })),
	};

	const options2 = {
		pattern: getpattern?.data?.map((data) => ({ label: data.full_version, value: data.full_version, image: data.image })),
	};

	const { mutate } = useMutation(addSingleColorOrBrand, {
		onSuccess: ({ message }) => {
			refetch();
			renderSuccessMessage(message);
		},
	});

	const PatternOption = (props) => {
		return (
			<div className="grid grid-cols-2">
				<components.Option {...props} />
				<div className="bg-repeat" style={{ backgroundImage: `url(${props.data.image})` }} onClick={props?.innerProps?.onClick} />
			</div>
		);
	};

	const ColorOption = (props) => {
		return (
			<div className="grid grid-cols-2">
				<components.Option {...props} />
				<div className="bg-repeat" style={{ backgroundColor: toHex(props.value.toLowerCase()) }} onClick={props?.innerProps?.onClick} />
			</div>
		);
	};

	return (
		<div className={`${index === itemKey ? "" : "hidden"}`}>
			<p className="text-[1.5rem] text-primary font-bold mb-[5px] capitalize">{item.name}</p>
			<p className="text-[1.4rem] text-lightText">Fill the form to complete your order</p>
			<div className="flex gap-2 mb-[30px] mt-[10px]">
				<Toggle id="cheese-status" defaultChecked={checked} onChange={(event) => setChecked(event.target.checked)} icons={false} />
				<label htmlFor="cheese-status">{checked ? "Input details using tag ID" : "Input details"}</label>
			</div>
			{checked ? (
				<CustomTextInput
					label="Tag"
					placeholder="Enter Tag"
					onChange={(event) => {
						const temp = [...values.items];
						temp[index].tag_id = event.target.value;
						setFieldValue("items", temp);
					}}
					containerClassName="mb-[20px]"
				/>
			) : (
				<div className="grid grid-cols-2 mt-3 gap-x-6 gap-y-6">
					<CustomMultiSelect
						label="Brand"
						name="brand"
						placeholder="Enter Brand"
						options={options?.brand}
						onChange={(value) => {
							const temp = [...values.items];
							temp[index].brand = value?.value;
							setFieldValue("items", temp);
						}}
						isClearable
						onCreateOption={(value) => {
							mutate({ brand: value });
							const temp = [...values.items];
							temp[index].brand = value;
							setFieldValue("items", temp);
						}}
					/>
					<CustomMultiSelect
						label="Color"
						name="color"
						isMulti
						placeholder="Enter Color"
						options={options?.color}
						onChange={(value) => {
							const temp = [...values.items];
							temp[index].extra_info = {
								...temp[index].extra_info,
								...{ color: value.reduce((prev, current) => prev.concat(current.label, ","), "").slice(0, -1) },
							};
							setFieldValue("items", temp);
						}}
						isClearable
						onCreateOption={(value) => {
							mutate({ color: value });
							const temp = [...values.items];
							temp[index].extra_info = {
								...temp[index].extra_info,
								...{ color: value },
							};
							setFieldValue("items", temp);
						}}
						components={{ Option: ColorOption }}
					/>
					<CustomMultiSelect
						label="Pattern"
						name="pattern"
						placeholder="Select Pattern"
						options={options2?.pattern}
						onChange={(value) => {
							const temp = [...values.items];
							temp[index].extra_info = {
								...temp[index].extra_info,
								...{ pattern: value?.value },
							};
							setFieldValue("items", temp);
						}}
						components={{ Option: PatternOption }}
						closeMenuOnSelect
					/>
					<CustomSelectInput
						label="Material"
						name="material"
						placeholder="Enter Cloth Material"
						options={options?.materials}
						onChange={(event) => {
							const temp = [...values.items];
							temp[index].extra_info = {
								...temp[index].extra_info,
								...{ [event.target.name]: event.target.value },
							};
							setFieldValue("items", temp);
						}}
					/>
					<CustomSelectInput
						label="Preference"
						name="style"
						placeholder="Enter Cloth Preference"
						options={options?.styles}
						onChange={(event) => {
							const temp = [...values.items];
							temp[index].extra_info = {
								...temp[index].extra_info,
								...{ [event.target.name]: event.target.value },
							};
							setFieldValue("items", temp);
						}}
					/>
					<CustomSelectInput
						label="Stains"
						name="stains"
						placeholder="Enter Cloth Stains"
						options={options?.stains}
						onChange={(event) => {
							const temp = [...values.items];
							temp[index].extra_info = {
								...temp[index].extra_info,
								...{ [event.target.name]: event.target.value },
							};
							setFieldValue("items", temp);
						}}
					/>
					<CustomMultiSelect
						label="Info"
						name="damages"
						isMulti
						placeholder="Select Info"
						options={options1?.damage_info}
						onChange={(value) => {
							const temp = [...values.items];
							temp[index].extra_info = {
								...temp[index].extra_info,
								damage: value.reduce((prev, current) => prev.concat(current.label, ","), "").slice(0, -1),
							};
							setFieldValue("items", temp);
						}}
						isClearable
					/>
					<CustomTextArea
						label="Notes"
						name="notes"
						placeholder="Enter Any Notes"
						onChange={(event) => {
							const temp = [...values.items];
							temp[index].extra_info = {
								...temp[index].extra_info,
								...{ [event.target.name]: event.target.value },
							};
							setFieldValue("items", temp);
						}}
						containerClassName={"!col-span-1"}
						rows={"2"}
					/>
				</div>
			)}

			{checked ? (
				<p className="text-[1rem] text-red-500">{!!errors?.items?.length && errors?.items[index]?.tag_id}</p>
			) : (
				<>
					<p className="text-[1rem] text-red-500">{!!errors?.items?.length && errors?.items[index]?.brand}</p>
					<p className="text-[1rem] text-red-500">{!!errors?.items?.length && errors?.items[index]?.extra_info?.color}</p>
					<p className="text-[1rem] text-red-500">{!!errors?.items?.length && errors?.items[index]?.extra_info?.material}</p>
					<p className="text-[1rem] text-red-500">{!!errors?.items?.length && errors?.items[index]?.extra_info?.pattern}</p>
				</>
			)}

			<div className="flex gap-4 mt-[35px]">
				<button className="ml-auto small-btn " type="button" onClick={index === 0 ? () => setIsModalOpen(false) : () => decrementIndex()}>
					{index === 0 ? "Cancel" : "Previous"}
				</button>
				<button
					type="button"
					className="small-btn-primary"
					onClick={index === initialValues.items.length - 1 ? () => submitForm() : () => (!!errors?.items[index] ? null : incrementIndex())}
				>
					{index === initialValues.items.length - 1 ? "Submit" : "Next"}
				</button>
			</div>
		</div>
	);
};

export default OrderItemDetails;
