import { useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Link } from "react-router-dom";
import { deleteProductGroup, getProductGroups } from "src/api";
import { renderConfirmDialogue, renderSuccessMessage } from "src/assets/functions";
import { EditIcon, DeleteIcon } from "src/assets/svg";
import { Modal } from "src/components";
import AddNewItem from "src/modals/settings/AddNewItem";

const ProductGroup = ({ openModal, setOpenModal }) => {
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	const [selectedGroup, setSelectedGroup] = useState(null);

	const { data, refetch } = useQuery({
		queryKey: ["product-groups"],
		queryFn: getProductGroups,
		suspense: true,
	});

	const { mutate } = useMutation(deleteProductGroup, {
		onSuccess: ({ message }) => renderSuccessMessage(message).then(() => refetch()),
	});

	const productGroups = data?.data;

	return (
		<>
			<ul className="product__group-list bg-[#fff] rounded-2xl">
				{productGroups.map((item) => (
					<li className="flex items-center justify-between border-b cursor-pointer" key={item.group_name}>
						<Link to={`/settings/product-group/${item.id}`} className="text-[1.6rem] capitalize px-10 py-10 flex-1">
							<h2>{item.group_name}</h2>
						</Link>
						<div className="flex px-10 py-10 item-center icons gap-7 ">
							<EditIcon
								className="text-primary"
								onClick={() => {
									setIsEditModalOpen(true);
									setSelectedGroup(item);
								}}
							/>
							<DeleteIcon
								className="text-primary"
								onClick={() =>
									renderConfirmDialogue("Do you want to delete this product group?").then((result) => {
										if (result.isConfirmed) mutate(item.id);
									})
								}
							/>
						</div>
					</li>
				))}
			</ul>
			<Modal isOpen={openModal} setIsOpen={setOpenModal} title="Add Product Group">
				<AddNewItem setOpenModal={setOpenModal} refetch={refetch} />
			</Modal>
			<Modal isOpen={isEditModalOpen} setIsOpen={setIsEditModalOpen} title="Edit Product Group">
				<AddNewItem setOpenModal={setIsEditModalOpen} refetch={refetch} selectedGroup={selectedGroup} />
			</Modal>
		</>
	);
};

export default ProductGroup;
