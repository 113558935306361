import LineGraph from "../LineGraph";
import { useSearchParams } from "react-router-dom";
import { getMetricsPeriod } from "src/api";
import { useQuery } from "@tanstack/react-query";
import { options, createDataset } from "src/assets/constants";
import { ctrlPercentageMetric } from "src/assets/functions";

const NewCustomerGraph = ({ metricPeriod }) => {
	const [searchParams] = useSearchParams();

	const startDate = searchParams.get("startDate") || "2023-08-07";
	const endDate = searchParams.get("endDate") || "2023-08-14";

	const period = metricPeriod;

	const { data } = useQuery({
		queryKey: ["metricsPeriod", startDate, endDate, period],
		queryFn: () => getMetricsPeriod({ startDate, endDate, period }),
		suspense: true,
	});

	const customerMetricsData = data?.data?.customers;

	const prepareChartData = (data) =>
		data.map((entry) => ({
			x: entry.register_date,
			y: entry.customer_count,
		}));

	const customerPeriod1Data = prepareChartData(customerMetricsData["customer-set-1"].customer);
	const customerPeriod2Data = prepareChartData(customerMetricsData["customer-set-2"].customer);

	const dataset1 = createDataset(customerPeriod1Data, "Customer Count", "#999");
	const dataset2 = createDataset(customerPeriod2Data, "Customer Count", "#0000A7");

	const chartData = {
		datasets: [dataset2, dataset1],
	};

	const [metricPercentage, percentageColor, percentageBg] = ctrlPercentageMetric(customerMetricsData?.percentageDifference)

	return (
		<div className="py-10 bg-white border graph-box px-14 rounded-xl">
			<div className="flex items-center gap-5 mb-12 title">
				<h2 className="text-3xl font-semibold">New Customers</h2>
				<span
					style={{
						color: percentageColor,
						backgroundColor: percentageBg,
					}}
					className={`text-xl transition-all text-[${percentageColor}] bg-[${percentageBg}] py-1 px-3 rounded-3xl !important`}
				>{`${metricPercentage}%`}</span>
			</div>
			<div className="flex justify-between prices">
				<div className="p1 text-primary">{``}</div>
				<div className="p2">{``}</div>
			</div>
			<div className="graph w-[430]">
				<LineGraph data={chartData} options={options} />
			</div>
		</div>
	);
};

export default NewCustomerGraph;
