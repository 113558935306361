import { ErrorMessage } from "formik";
import React from "react";

const CustomSelectInput = ({ label, options, placeholder, placeholderValue = "", ...rest }) => {
	return (
		<div className="flex flex-col gap-1">
			<label htmlFor={label} className="text-[1.4rem] text-lightText font-medium mb-[10px]">
				{label}
			</label>
			<select
				id={label}
				defaultValue=""
				className="border border-lightGray rounded-md p-[10px] text-[1.3rem] placeholder:text-[1.3rem] text-lightText"
				{...rest}
			>
				<option value={placeholderValue} disabled>
					{placeholder}
				</option>
				{options?.map((option, index) => (
					<option value={option.value} key={index}>
						{option.label}
					</option>
				))}
			</select>
			{!!rest["name"] && <ErrorMessage name={rest["name"]} component="div" className="text-red-500 text-[1rem]" />}
		</div>
	);
};

export default CustomSelectInput;
