import {
    Chart as ChartJS,

    BarElement,

} from 'chart.js';

import { Bar } from 'react-chartjs-2';

ChartJS.register(
    BarElement,
);


const BarChart = ({ data, options, id }) => {
    return (
        <div>
            <Bar
                data={data}
                options={options}
                id={id}
            />
        </div>
    )
}

export default BarChart