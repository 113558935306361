import { useMutation } from "@tanstack/react-query";
import React from "react";
import { staffClockIn } from "src/api";
import { renderErrorMessage, renderSuccessMessage } from "src/assets/functions";
import { useProtectedRoutesContext } from "src/context/ProtectedRoutes";

const ClockInNow = ({ setIsModalOpen, refetch }) => {
	const { user } = useProtectedRoutesContext();

	const { mutate, isLoading } = useMutation(staffClockIn, {
		onSuccess: ({ message }) => renderSuccessMessage(message).then(() => refetch()),
		onError: (error) => renderErrorMessage(error.message),
	});
	return (
		<button className="w-full mt-6 btn-primary" disabled={isLoading} onClick={() => mutate({ id: user.id })}>
			Clock In
		</button>
	);
};

export default ClockInNow;
