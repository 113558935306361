import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { renderErrorMessage, renderSuccessMessage } from "src/assets/functions";
import { CustomSelectInput, CustomTextInput } from "src/components";
import { useMutation, useQuery } from "@tanstack/react-query";
import { createStaff, editStaff, getAllDepartments, getAllLocation, getAllRoles } from "src/api";

const CreateStaff = ({ staff, setIsStaffModalOpen, refetch }) => {
	const initialValues = staff
		? {
				uuid: staff?.uuid,
				fullName: staff?.fullName,
				phoneNumber: staff?.phoneNumber,
				department_id: staff?.department_id,
				email: staff?.email,
				role_id: staff?.role_id,
				location_id: staff?.location_id,
		  }
		: {
				fullName: "",
				email: "",
				phoneNumber: "",
				password: "",
				department_id: "",
				location_id: "",
				role_id: "",
		  };

	const validationSchema = Yup.object().shape({
		fullName: Yup.string().required("Staff name is required"),
		phoneNumber: Yup.string().required("Staff phone number is required"),
		department_id: Yup.string().required("Department is required"),
		email: Yup.string().email("Please provide a valid email").required("Please provide a valid email"),
		password: Yup.string()
			.required("Please provide password")
			.min(8, "Password must be 8 characters long")
			.matches(/[0-9]/, "Password requires a number")
			.matches(/[a-z]/, "Password requires a lowercase letter")
			.matches(/[A-Z]/, "Password requires an uppercase letter")
			.matches(/[^\w]/, "Password requires a symbol"),
		role_id: Yup.string().required(" Role is required"),
		location_id: Yup.string().required(" Location is required"),
	});

	const editValidationSchema = Yup.object().shape({
		fullName: Yup.string().required("Staff name is required"),
		phoneNumber: Yup.string().required("Staff phone number is required"),
		department_id: Yup.string().required("Department is required"),
		email: Yup.string().email("Please provide a valid email").required("Please provide a valid email"),
		password: Yup.string()
			.min(8, "Password must be 8 characters long")
			.matches(/[0-9]/, "Password requires a number")
			.matches(/[a-z]/, "Password requires a lowercase letter")
			.matches(/[A-Z]/, "Password requires an uppercase letter")
			.matches(/[^\w]/, "Password requires a symbol"),
		role_id: Yup.string().required(" Role is required"),
		location_id: Yup.string().required(" Location is required"),
	});

	const { data: allRoles } = useQuery({
		queryKey: ["allRoles"],
		queryFn: getAllRoles,
		suspense: true,
	});
	const { data: allDepartment } = useQuery({
		queryKey: ["allDepartments"],
		queryFn: getAllDepartments,
		suspense: true,
	});
	const { data: allLocations } = useQuery({
		queryKey: ["allLocations"],
		queryFn: getAllLocation,
		suspense: true,
	});

	const { mutate, isLoading } = useMutation(staff ? editStaff : createStaff, {
		onSuccess: (res) => {
			renderSuccessMessage(res.message);
			refetch();
			setIsStaffModalOpen(false);
		},
		onError: (error) => {
			renderErrorMessage(error.message);
		},
	});

	return (
		<>
			<Formik
				initialValues={initialValues}
				validationSchema={staff ? editValidationSchema : validationSchema}
				onSubmit={(values) => mutate(values)}
			>
				{({ values, handleChange, handleSubmit }) => (
					<form onSubmit={handleSubmit} className="flex flex-col">
						<div className="grid grid-cols-2 gap-10 my-10">
							<CustomTextInput
								label="Full Name"
								name="fullName"
								onChange={handleChange}
								value={values.fullName}
								placeholder="Enter Full Name"
							/>

							<CustomTextInput
								type="email"
								label="Email"
								name="email"
								onChange={handleChange}
								value={values.email}
								placeholder="Enter Email"
								autoComplete="off"
							/>

							<CustomTextInput
								label="Phone Number"
								name="phoneNumber"
								onChange={handleChange}
								value={values.phoneNumber}
								placeholder="Enter Phone Number"
							/>

							<CustomTextInput
								type="password"
								label="Password"
								name="password"
								onChange={handleChange}
								value={values.password}
								placeholder="Enter Password"
								autoComplete="off"
							/>

							<CustomSelectInput
								label="Role"
								name="role_id"
								onChange={handleChange}
								value={values.role_id}
								placeholder="Select Role"
								options={allRoles?.data?.map((role) => ({
									value: role?.id,
									label: role?.name,
								}))}
							/>
							<CustomSelectInput
								label="Department"
								name="department_id"
								onChange={handleChange}
								value={values.department_id}
								placeholder="Select Department"
								options={allDepartment?.data?.map((department) => ({
									value: department?.id,
									label: department?.name,
								}))}
							/>

							<CustomSelectInput
								label="Location"
								name="location_id"
								onChange={handleChange}
								value={values.location_id}
								placeholder="Select Location"
								containerClassName="col-span-1"
								options={allLocations?.data?.map((location) => ({
									value: location?.id,
									label: location?.locationName,
								}))}
							/>
						</div>
						<div className="flex gap-3 ml-auto">
							<button
								type="button"
								className="btn"
								onClick={() => {
									setIsStaffModalOpen(false);
								}}
							>
								Cancel
							</button>
							<button type="submit" disabled={isLoading} className="btn-primary">
								{staff ? "Edit" : "Add"}
							</button>
						</div>
					</form>
				)}
			</Formik>
		</>
	);
};

export default CreateStaff;
