import LineGraph from "../LineGraph";
import { useSearchParams } from "react-router-dom";
import { getMetricsPeriod } from "src/api";
import { useQuery } from "@tanstack/react-query";
import { options, createDataset, prepareChartData } from "src/assets/constants";
import { ctrlMetricNumber, ctrlPercentageMetric } from "src/assets/functions";

const AverageSpendGraph = ({ metricPeriod }) => {
    const [searchParams] = useSearchParams();

    const startDate = searchParams.get("startDate") || "2023-08-07";
    const endDate = searchParams.get("endDate") || "2023-08-14";

    const period = metricPeriod;

    const { data } = useQuery({
        queryKey: ["metricsPeriod", startDate, endDate, period],
        queryFn: () => getMetricsPeriod({ startDate, endDate, period }),
        suspense: true,
    });

    const avgMetricsData = data?.data?.average;

    const avgPeriod1Data = prepareChartData(avgMetricsData["avg-period-1"].avg);
    const avgPeriod2Data = prepareChartData(avgMetricsData["avg-period-2"].avg);

    const dataset1 = createDataset(avgPeriod1Data, "Total", "#999");
    const dataset2 = createDataset(avgPeriod2Data, "Total", "#0000A7");

    const chartData = {
        datasets: [dataset2, dataset1],
    };

    const [metricPercentage, percentageColor, percentageBg] = ctrlPercentageMetric(data?.data?.average?.percentageDifference)

    const [averagePrice1, averagePrice2] = ctrlMetricNumber(
        data?.data?.average["avg-period-1"]["avg-period1-total"],
        data?.data?.average["avg-period-2"]["avg-period1-total"],
        'NGN', 2

    )

    return (
        <div className="graph-box bg-white py-10 px-14 rounded-xl border">
            <div className="title flex gap-5 items-center mb-12">
                <h2 className="text-3xl font-semibold">Avg Spend per Customer</h2>
                <span
                    style={{
                        color: percentageColor,
                        backgroundColor: percentageBg,
                    }}
                    className={`text-xl transition-all text-[${percentageColor}] bg-[${percentageBg}] py-1 px-3 rounded-3xl !important`}
                >{`${metricPercentage}%`}</span>
            </div>
            <div className="prices flex justify-between">
                <div className="p1 text-[#0000A7]">{`${averagePrice2}`}</div>
                <div className="p2">{`${averagePrice1}`}</div>
            </div>
            <div className="graph w-[430]">
                <LineGraph data={chartData} options={options} />
            </div>
        </div>
    );
};

export default AverageSpendGraph;
