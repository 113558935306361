import { useMutation, useQuery } from "@tanstack/react-query";
import { Formik } from "formik";
import { EditOrderOption, getOrderOption } from "src/api";
import { renderErrorMessage, renderSuccessMessage } from "src/assets/functions";
import { CustomTextInput } from "src/components";
import * as yup from "yup";

const SetCleaningDuration = () => {
	const { data: getduration } = useQuery({
		queryKey: ["getOrderOption"],
		queryFn: getOrderOption,
		suspense: true,
	});

	const initialValues = {
		days: getduration?.data?.number_of_days ?? "",
		max_order_item: getduration?.data?.max_order_item ?? "",
	};

	const validationSchema = yup.object().shape({
		days: yup.number().required("Duration is required"),
		max_order_item: yup.number().required("Maximum items per order is required"),
	});

	const { mutate, isLoading } = useMutation(EditOrderOption, {
		onSuccess: (data) => {
			renderSuccessMessage(data.message).then(() => {});
		},
		onError: (error) => {
			renderErrorMessage(error.message);
		},
	});

	return (
		<>
			<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={(values) => mutate(values)}>
				{({ values, handleChange, handleSubmit }) => (
					<form onSubmit={handleSubmit} className="max-w-[1300px]">
						<div className="grid grid-cols-2 gap-4 mb-[50px]">
							<CustomTextInput
								label="Set Ready by Duration (Days)"
								name="days"
								onChange={handleChange}
								value={values.days}
								placeholder="Enter Days"
							/>
							<CustomTextInput
								label="Max Items per Order"
								name="max_order_item"
								onChange={handleChange}
								value={values.max_order_item}
								placeholder="Enter Maximum items per order"
							/>
						</div>
						<div className="">
							<button type="submit" className="btn-primary" disabled={isLoading}>
								Submit
							</button>
						</div>
					</form>
				)}
			</Formik>
		</>
	);
};
export default SetCleaningDuration;
