import { ErrorMessage } from "formik";
import React from "react";
import CreatableSelect from "react-select/creatable";

const CustomMultiSelect = ({ label, containerClassName = "", ...rest }) => {
	return (
		<div className={`flex flex-col gap-1 ${containerClassName}`}>
			<label htmlFor={label} className={`text-[1.4rem] text-lightText font-medium mb-[10px]`}>
				{label}
			</label>
			<CreatableSelect
				closeMenuOnSelect={false}
				isMulti={false}
				styles={{
					control: (styles) => ({
						...styles,
						borderRadius: "0.375rem",
						minHeight: "auto",
						padding: 0,
						borderColor: "#F2F4F7",
					}),
					container: (styles) => ({ ...styles, height: 41 }),
					placeholder: (styles) => ({ ...styles, color: "#64748B", fontSize: "1.3rem" }),
					singleValue: (styles) => ({ ...styles, color: "#64748B", fontSize: "1.3rem" }),
				}}
				{...rest}
			/>
			{!!rest["name"] && <ErrorMessage name={rest["name"]} component="div" className="text-red-500 text-[1rem]" />}
		</div>
	);
};

export default CustomMultiSelect;
