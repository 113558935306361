import { useMutation } from "@tanstack/react-query";
import { Formik } from "formik";
import React from "react";
import { createProductOption, editProductOption } from "src/api";
import { renderSuccessMessage } from "src/assets/functions";
import { CustomTextInput } from "src/components";
import * as Yup from "yup";

const AddNewProductOption = ({ setOpenModal, refetch, selectedProductOption, product }) => {
	const initialValues = selectedProductOption
		? {
				id: selectedProductOption?.id,
				option_name: selectedProductOption?.option_name,
				price: selectedProductOption?.price,
				expressPrice: selectedProductOption?.expressPrice,
				product_id: selectedProductOption?.product_id,
				cost_price: selectedProductOption?.cost_price,
				pieces: selectedProductOption?.pieces,
		  }
		: { option_name: "", price: "", expressPrice: "", product_id: product?.id, cost_price: "", pieces: "" };

	const validationSchema = Yup.object().shape({
		option_name: Yup.string().required("Option name is required"),
		price: Yup.string().required("Option price is required"),
		expressPrice: Yup.string().required("Option express price is required"),
		cost_price: Yup.string().required("Option cost price is required"),
	});

	const { mutate, isLoading } = useMutation(selectedProductOption ? editProductOption : createProductOption, {
		onSuccess: ({ message }) =>
			renderSuccessMessage(message).then(() => {
				refetch();
				setOpenModal(false);
			}),
	});

	return (
		<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={(values) => mutate(values)}>
			{({ values, handleChange, handleSubmit }) => (
				<form onSubmit={handleSubmit} className="flex flex-col gap-5">
					<CustomTextInput label="Product Name" name="option_name" onChange={handleChange} value={values.option_name} />

					<CustomTextInput label="Price" name="price" onChange={handleChange} value={values.price} type="number" min={0} />
					<CustomTextInput
						label="Express Price"
						name="expressPrice"
						onChange={handleChange}
						value={values.expressPrice}
						type="number"
						min={0}
					/>
					<CustomTextInput label="Cost Price" name="cost_price" onChange={handleChange} value={values.cost_price} type="number" min={0} />
					<CustomTextInput label="Pieces" name="pieces" onChange={handleChange} value={values.pieces} type="number" min={1} />
					<div className="flex gap-3 ml-auto">
						<button className="btn" type="button" onClick={() => setOpenModal(false)}>
							Cancel
						</button>
						<button className="btn-primary" disabled={isLoading}>
							Save
						</button>
					</div>
				</form>
			)}
		</Formik>
	);
};

export default AddNewProductOption;
