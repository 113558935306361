import { ErrorMessage } from "formik";
import React from "react";

const CustomTextArea = ({ label, containerClassName, name, ...rest }) => {
	return (
		<div className={`flex flex-col col-span-2 gap-1 ${containerClassName}`}>
			<label htmlFor={label} className="text-[1.4rem] text-lightText font-medium mb-[10px]">
				{label}
			</label>
			<textarea
				id={label}
				rows="5"
				className={`border border-lightGray rounded-md p-[10px] text-[1.3rem] placeholder:text-[1.3rem] text-lightText  ${rest.className}`}
				name="name"
				{...rest}
			></textarea>
			{!!name && <ErrorMessage name={name} component="div" className="text-red-500 text-[1rem]" />}
		</div>
	);
};

export default CustomTextArea;
