import React, { useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { deleteRole, getAllRoles } from "src/api";
import { renderErrorMessage, renderSuccessMessage } from "src/assets/functions";
import { DeleteIcon, EditIcon } from "src/assets/svg";
import { CustomDataTable, Modal } from "src/components";
import CreateRole from "src/modals/staff/CreateRole";
import { renderConfirmDialogue } from "../../../assets/functions";

const Roles = () => {
	const [isRoleModalOpen, setIsRoleModalOpen] = useState(false);
	const [selectedRole, setSelectedRole] = useState(null);
	const [btnTitle, setBtnTitle] = useState("");
	const [roleModalTitle, setRoleModalTitle] = useState("");

	const columns = [
		{
			name: "S/N",
			selector: (row, index) => index + 1,
			compact: true,
			width: "150px",
		},
		{
			name: "Title",
			cell: (row) => <div className="capitalize">{row?.name}</div>,
			compact: true,
			width: 1000,
		},
		{
			name: "Permission Level",
			selector: (row) => (row?.permission_id ? row?.permission_id : "1"),
			compact: true,
		},
		{
			name: "",
			cell: (row) => (
				<div className="flex items-center gap-4 justify-items-center">
					<EditIcon
						className="cursor-pointer stroke-primary text-primary"
						onClick={() => {
							setSelectedRole(row);
							setIsRoleModalOpen(true);
							setRoleModalTitle("Edit Role Details");
							setBtnTitle("editRole");
						}}
					/>
					<DeleteIcon
						className="cursor-pointer stroke-primary text-primary"
						onClick={() =>
							renderConfirmDialogue("Are you sure you want to delete this role?").then((res) => {
								if (res.isConfirm) {
									deleteOneRole(row?.id);
								}
							})
						}
					/>
				</div>
			),
			width: "50px",
			compact: true,
		},
	];

	const { data: allRoles, refetch: refetchRole } = useQuery({
		queryKey: ["allRoles"],
		queryFn: getAllRoles,
		suspense: true,
	});

	const { mutate: deleteOneRole } = useMutation(deleteRole, {
		onSuccess: (res) => {
			renderSuccessMessage(res.message);
			refetchRole();
		},
		onError: (error) => {
			renderErrorMessage(error.message);
		},
	});

	return (
		<>
			<div className="flex justify-end my-10">
				<button
					type="button"
					className="btn-primary"
					onClick={() => {
						setIsRoleModalOpen(true);
						setRoleModalTitle("Create Role");
						setBtnTitle("addRole");
					}}
				>
					Add Role
				</button>
			</div>
			<CustomDataTable columns={columns} data={allRoles?.data} />

			<Modal isOpen={isRoleModalOpen} setIsOpen={setIsRoleModalOpen} title={roleModalTitle} size="small">
				<CreateRole role={btnTitle === "editRole" ? selectedRole : null} setIsRoleModalOpen={setIsRoleModalOpen} refetch={refetchRole} />
			</Modal>
		</>
	);
};

export default Roles;
