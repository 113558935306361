import React from 'react'
import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import { getMetricsPeriod } from 'src/api';
import BarChart from '../BarChart';
import { BarOptions } from 'src/assets/constants';

const TopItems = ({ metricPeriod }) => {
    const [searchParams] = useSearchParams();

    const startDate = searchParams.get('startDate') || '2023-08-07'
    const endDate = searchParams.get('endDate') || "2023-08-14"
    const period = metricPeriod;

    const {
        data,
    } = useQuery({
        queryKey: ["metricsPeriod", startDate, endDate, period],
        queryFn: () => getMetricsPeriod({ startDate, endDate, period }),
        suspense: true
    });


    const chartData = {
        labels: data?.data?.topItems[0].map(item => item.option_name),
        datasets: [
            {
                data: data?.data?.topItems[0].map(item => item.total_paid_amount),
                backgroundColor: '#0000A7',
                borderWidth: 0,
                borderSkipped: false,
                borderRadius: 10,
                barPercentage: 0.7,
                categoryPercentage: 0.8,
            },
        ],
    };

    return (
        <div className="graph-box bg-white py-10 px-14 rounded-xl border">
            <div className="title flex gap-5 items-center">
                <h2 className='text-3xl font-semibold'>Top Items</h2>
            </div>
            <div className="graph">
                <BarChart data={chartData} options={BarOptions} />
            </div>
        </div>
    )
}

export default TopItems