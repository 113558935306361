import { useMutation } from "@tanstack/react-query";
import { Formik } from "formik";
import React from "react";
import { createDeliveryOption, editDeliveryOption } from "src/api";
import { renderErrorMessage, renderSuccessMessage } from "src/assets/functions";
import { CustomTextInput } from "src/components";
import * as Yup from "yup";

const AddDelivery = ({ option, refetch, setOpenModal }) => {
	const initialValues = option
		? {
				id: option.id,
				name: option.name,
				amount: option.amount,
		  }
		: {
				name: "",
				amount: "",
		  };
	const validationSchema = Yup.object().shape({
		name: Yup.string().required("Delivery option name is required"),
		amount: Yup.number().min(0, "Delivery option price cannot be less than 1"),
	});

	const { mutate, isLoading } = useMutation(option ? editDeliveryOption : createDeliveryOption, {
		onSuccess: (data) => {
			renderSuccessMessage(data.message).then(() => {
				refetch();
				setOpenModal(false);
			});
		},
		onError: (error) => {
			renderErrorMessage(error.message);
		},
	});

	return (
		<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={(values) => mutate(values)}>
			{({ values, handleChange, handleSubmit }) => (
				<form onSubmit={handleSubmit} className="flex flex-col">
					<div className="flex flex-col gap-10 mb-20">
						<CustomTextInput
							label="Name"
							name="name"
							onChange={handleChange}
							value={values.name}
							placeholder="Name"
						/>
						<CustomTextInput
							label="Amount"
							name="amount"
							onChange={handleChange}
							value={values.amount}
							placeholder="Amount"
						/>
					</div>
					<div className="flex gap-3 ml-auto">
						<button className="btn" type="button" onClick={() => setOpenModal(false)}>
							Cancel
						</button>
						<button className="btn-primary" disabled={isLoading}>
							Save
						</button>
					</div>
				</form>
			)}
		</Formik>
	);
};

export default AddDelivery;
