import { useQuery } from "@tanstack/react-query";
import { format, parseISO } from "date-fns";
import React from "react";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { getSingleMovementSheet } from "src/api";

const PrintSheet = () => {
	const componentRef = React.useRef(null);

	const reactToPrintContent = React.useCallback(() => {
		return componentRef.current;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [componentRef.current]);

	const handlePrint = useReactToPrint({
		content: reactToPrintContent,
		documentTitle: "Print Tag",
		removeAfterPrint: true,
	});

	return (
		<div className="flex flex-col items-center justify-center min-h-[50vh] gap-3">
			<ComponentToPrint ref={componentRef} />
			<button className=" btn-primary" onClick={handlePrint}>
				Print
			</button>
		</div>
	);
};

const ComponentToPrint = React.forwardRef((props, ref) => {
	return (
		<div ref={ref} className="flex flex-col items-center text-center w-fit">
			<p className="font-semibold text-[1.2rem]">CLEANACE DRYCLEANERS</p>
			<div dangerouslySetInnerHTML={{ __html: props?.data?.barcode }} className="w-[250px] mb-1"></div>
			<PrintInfo />
		</div>
	);
});

const PrintInfo = () => {
	const { id } = useParams();

	const { data } = useQuery({
		queryKey: ["single-sheet", id],
		queryFn: () => getSingleMovementSheet({ id }),
		suspense: true,
	});

	return (
		<>
			<p className="font-bold text-[2.4rem]">{data?.data?.driver_name}</p>
			<p className="font-bold text-[1.4rem]">{data?.data?.locationName}</p>
			<p className="font-normal text-[1.2rem] mb-5">Created By: {data?.data?.rep_name}</p>
			<p className="font-bold text-[1.6rem]">Number of Bags: {data?.data?.total_bags}</p>

			<ul className="my-5 !list-disc">
				{data?.data?.order_ids?.split(",")?.map((order) => (
					<li>{order}</li>
				))}
			</ul>
			<p className="font-bold text-[1.4rem]">Date Created: {format(parseISO(data?.data?.created_at), "dd MMM yyyy")}</p>
		</>
	);
};

export default PrintSheet;
