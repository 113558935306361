import React from "react";
import { Suspense } from "react";
import ReactModal from "react-modal";
import Loader from "./Loader";

const Modal = ({ size, children, ...rest }) => {
	let modalClass;
	if (size === "xl") {
		modalClass = "modal-xlg";
	} else if (size === "large") {
		modalClass = "modal-lg";
	} else if (size === "sm") {
		modalClass = "modal-normal";
	} else if (size === "xsm") {
		modalClass = "modal-xsm";
	} else {
		modalClass = "modal-normal";
	}
	return (
		<ModalBody modalClass={modalClass} {...rest}>
			<Suspense fallback={<Loader spin />}>{children}</Suspense>
		</ModalBody>
	);
};

const ModalBody = ({ children, isOpen, setIsOpen, title, subtitle, modalClass, onClose = () => {} }) => {
	return (
		<ReactModal isOpen={isOpen} ariaHideApp={false}>
			<div className={`modal-dialog ${modalClass}`} role="document">
				<div className="modal-content app-modal-content">
					<div className="modal-header">
						<h4 className="capitalize modal-title">{title}</h4>

						<button
							type="button"
							className="close"
							data-dismiss="modal"
							aria-label="Close"
							onClick={() => {
								setIsOpen(false);
								onClose();
							}}
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<h6 className="modal-subtitle">{subtitle}</h6>
					<div className="modal-body rec-modal-body">{children}</div>
				</div>
			</div>
		</ReactModal>
	);
};

export default Modal;
