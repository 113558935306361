import { useEffect } from "react";
import { Loader } from "src/components";

const Logout = () => {
	useEffect(() => {
		const logout = () => {
			localStorage.clear();
			window.location = "/";
		};
		logout();
	}, []);

	return <Loader />;
};

export default Logout;
