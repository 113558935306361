import React from "react";
import unpaidIcon from "src/assets/images/unpaid1.png";
import { useQuery } from "@tanstack/react-query";
import { getUnpaidMetrics } from "src/api";
import { renderCurrency } from "src/assets/functions";
import { CustomDataTable } from "src/components";

const Unpaid = () => {
	const columns = [
		{
			name: "Name",
			selector: (row) => row?.full_name,
		},
		{
			name: "Orders",
			selector: (row) => row?.Orders,
		},
		{
			name: "Value",
			selector: (row) => renderCurrency(row?.total),
		},
	];

	const { data } = useQuery({
		queryKey: ["getUnpaidMetrics"],
		queryFn: getUnpaidMetrics,
		suspense: true,
	});

	const unpaidDataNumber = data?.data?.unPaidNumbers[0];

	const unpaidDataOrder = data?.data?.unpaidOrders;

	return (
		<div className="px-[2.5rem]">
			<div className="flex justify-between ">
				<h2 className="my-[30px] text-black text-[2rem] font-bold">Unpaid</h2>
				<div className="my-[30px]">
					<select>
						<option>All</option>
						<option>Over the last 60 days</option>
					</select>
				</div>
			</div>
			<div className="grid grid-cols-3 mb-8 border border-gray-300 divide-x divide-gray-300 rounded-lg">
				<div className="px-4 py-5">
					<div className="flex justify-between">
						<p className="text-[1.4rem] text-darkText font-medium">Unpaid</p>
						<img src={unpaidIcon} alt="" />
					</div>
					<p className="text-[2rem] leading-[3rem] font-bold">{renderCurrency(unpaidDataNumber?.total)}</p>
				</div>
				<div className="px-4 py-5">
					<div className="flex justify-between">
						<p className="text-[1.4rem] text-darkText font-medium">Unpaid Invoices</p>
						<img src={unpaidIcon} alt="" />
					</div>
					<p className="text-[2rem] leading-[3rem] font-bold"></p>
				</div>
				<div className="px-4 py-5">
					<div className="flex justify-between">
						<p className="text-[1.4rem] text-darkText font-medium">Total Unpaid</p>
						<img src={unpaidIcon} alt="" />
					</div>
					<p className="text-[2rem] leading-[3rem] font-bold"></p>
				</div>
			</div>
			<div>
				<h2 className="mb-[20px] mt-[50px] text-[#49515C] font-[700]">Unpaid Orders</h2>
				<CustomDataTable columns={columns} data={unpaidDataOrder?.data} />
			</div>
		</div>
	);
};
export default Unpaid;
