import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Roles from "./Roles";
import Staff from "./Staff";

const Users = () => {
	return (
		<>
			<Tabs className="my-[3rem]">
				<TabList className="border-b ">
					<Tab className="modal-tabs__tab" selectedClassName="modal-tabs__tab--selected">
						Staff
					</Tab>
					<Tab className="modal-tabs__tab" selectedClassName="modal-tabs__tab--selected">
						Roles
					</Tab>
				</TabList>

				<TabPanel className="modal-tabs__tab-panel" selectedClassName="modal-tabs__tab-panel--selected">
					<Staff />
				</TabPanel>
				<TabPanel className="modal-tabs__tab-panel" selectedClassName="modal-tabs__tab-panel--selected">
					<Roles />
				</TabPanel>
			</Tabs>
		</>
	);
};

export default Users;
